import './AddUser.css';
import HomeBack from '../Components/HomeBack';
import SideBar from '../Components/SideBar';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyIcon from '@mui/icons-material/Key';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Helper from '../Util/Helper';

const MerchantDetail = ({ refreshMerchant, merchantId, setMerchantId }) => {
    // console.log("merchant id", merchantId);
    const [mdData, setMdData] = useState(null);
    const [mdCategory, setMdCategory] = useState(null);
    const [masterCategoryList, setMasterCategoryList] = useState([]);
    const [masterCategoryName, setMasterCategoryName] = useState(null);

    

    const navigate = useNavigate();
    useEffect(() => {
        if (refreshMerchant != null) {

        } else {
            navigate("/ViewMerchants")
        }
    }, [])

    const merchantDetail = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getMerchantDetail?contactId=${merchantId}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                // console.log(response.data)
                setMdData(response.data[0])

            })
            .catch((error) => {
                console.log(error);
            })
    }

    const merchantCategory = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getMerchantCategory?contactId=${merchantId}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                // console.log("category detailssssssssssss", response.data)
                setMdCategory(response.data[0].master_category_id)
                // setMdData(response.data[0])

            })
            .catch((error) => {
                console.log(error);
            })
    }

    const masterCategory = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getMasterCategory`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                // console.log("master category", response.data)
                setMasterCategoryList(response.data)
                // setMdCategory(response.data[0].master_category_id)
                // setMdData(response.data[0])

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (masterCategoryList && masterCategoryList.length > 0 && mdCategory && mdCategory != null) {
            masterCategoryList && masterCategoryList.filter((value) => {
                if (value.id === mdCategory) {
                    setMasterCategoryName(value.name)
                }
            })
        }
    }, [mdCategory, masterCategoryList])

    // console.log("SSSDSDD : ", masterCategoryName)


    useEffect(() => {
        merchantDetail();
        merchantCategory();
        masterCategory();
    }, [])
    return (
        <>
            <div className='row Adduser' style={{ margin: "0", padding: "0" }}>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>Merchant Details</h4>

                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Name</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.name}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Entity</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.entity_id}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Pincode</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.Pincode_text}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>City</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.city_text}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>State</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.state_text}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Landmark</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.landmark}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Address</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.address}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Have GST</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.gst === 0 ? "no" : mdData && mdData.gst === 1 ? "yes" : null}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>GST Number</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.gst_number}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Udhaar</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.loan_type === 0 ? "no" : mdData && mdData.loan_type === 1 ? "yes" : null}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Category</p>
                            </div>
                            <div className='rightContent'>
                                <p>{masterCategoryName && masterCategoryName}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Upi ID</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.upi_id}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Aadhar Front</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.adhaar_front === 0 ? "no" : mdData && mdData.adhaar_front === 1 ? "yes" : null}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Aadhar Back</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.adhaar_back === 0 ? "no" : mdData && mdData.adhaar_back === 1 ? "yes" : null}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>
                    <div className='MDcontentContainer' >
                        <div className='MDcontent' >
                            <div className='leftContent' >
                                <p style={{ fontWeight: "900" }}>Pan Card</p>
                            </div>
                            <div className='rightContent'>
                                <p>{mdData && mdData.pancard}</p>
                            </div>
                            <div>

                            </div>
                        </div>
                        <hr style={{ margin: "0" }}></hr>

                    </div>

                </div>
            </div>
        </>
    )
}
export default MerchantDetail
const Footer = () => {
    return (
        <>
           
            <div className='row footer' >
                <p>© Copyright 2022 | Enbill | All rights reserved.</p>
            </div>
        </>
    )
}
export default Footer;
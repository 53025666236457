import './Dashboard.css';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Person from '@mui/icons-material/Person';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SideBar from '../Components/SideBar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Helper from '../Util/Helper';


const Dashboard = ({ fromMerchant, setFromMerchant, fromCustomers, setFromCustomer }) => {
    const [totalItem, setTotalItem] = useState([]);
    const [totalBill, setTotalBill] = useState([]);
    const [totalVm, setTotalVm] = useState([]);

    const getBookingChat = () => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl()() + `/api/get`,
            url: Helper.getWsUrl() + `/api/get`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {

                // setTotalItem(response.data.data)
                setTotalItem(response.data[0].live_item)
                // console.log("abcs",totalItem)
                // console.log("chat data ", Helper.encypt_decrypt(response.data.data))

            })
            .catch((error) => {
                console.log(error);
            })
    }

    const forError = () => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl()() + `/api/get`,
            url: Helper.getWsUrl() + `/api/totalError`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
            // headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {
                console.log("erorrrrrrr", response.data)
                // setTotalItem(response.data.data)
                // setTotalItem(response.data[0].live_item)
                // console.log("abcs",totalItem)
                // console.log("chat data ", Helper.encypt_decrypt(response.data.data))

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getBookingChat()
        getTotalBill()
        getTotalVm()
        // forError()
    }, [])

    const getTotalBill = () => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl()() + `/api/get`,
            url: Helper.getWsUrl() + `/api/getDashboardBill`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
            // headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {

                // setTotalItem(response.data.data)
                setTotalBill(response.data[0].bc)

                // console.log("abcs",totalItem)
                // console.log("chat data ", Helper.encypt_decrypt(response.data.data))

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getTotalVm = () => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl()() + `/api/get`,
            url: Helper.getWsUrl() + `/api/getDashboardVM`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
            // headers: { "Content-Type": "application/json", 'token': cookies.token },
        };
        axios(patchItem)
            .then((response) => {

                // setTotalItem(response.data.data)
                setTotalVm(response.data[0].count)
                // console.log("data total vmmmmm", response.data)
                // console.log("abcs",totalItem)
                // console.log("chat data ", Helper.encypt_decrypt(response.data.data))

            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <>
            <div className='row' >
                <SideBar fromMerchant={fromMerchant} setFromMerchant={setFromMerchant} fromCustomers={fromCustomers} setFromCustomer={setFromCustomer}></SideBar>
                <div className='col-7 col-lg-10  main-dashboard'>
                    <div className='row'>
                        <h4 style={{ marginTop: "40px" }}>Dashboard</h4>
                        <div className='col dashboardContentWrapper' >
                            <div className='dashboardContentBox'>
                                <div className='dashboardContentbtn' >Total Downloaded Merchants</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}></h3>
                                </div>
                            </div>
                            <div className='dashboardContentBox' style={{ marginTop: "15%" }}>
                                <div className='dashboardContentbtn' >Total Bills</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}>{totalBill && totalBill}</h3>
                                </div>
                            </div>
                            <div className='dashboardContentBox' style={{ marginTop: "15%" }}>
                                <div className='dashboardContentbtn' >Total Database</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}></h3>
                                </div>
                            </div>

                        </div>
                        <div className='col dashboardContentWrapper lg-m-5' >
                            <div className='dashboardContentBox'>
                                <div className='dashboardContentbtn' >Verified Merchants</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}>{totalVm && totalVm}</h3>
                                </div>
                            </div>
                            <div className='dashboardContentBox' style={{ marginTop: "15%" }}>
                                <div className='dashboardContentbtn' >Total Items</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}>{totalItem && totalItem}</h3>
                                </div>
                            </div>
                            <div className='dashboardContentBox' style={{ marginTop: "15%" }}>
                                <div className='dashboardContentbtn' >GMV</div>
                                <div className='dashboardContentData'>
                                    <h3 style={{ marginLeft: "5px" }}></h3>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
export default Dashboard
import SideBar from "../Components/SideBar";
import HomeBack from "../Components/HomeBack";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Helper from "../Util/Helper";

const ReviewItem = ({ itemName, itemId, setItemId, itemCategoryId, setItemCategoryId, masterCategoryId, setMasterCategoryId }) => {
    // console.log("master", masterCategoryId)
    // console.log("itemid", itemId)
    // console.log("itemCatid", itemCategoryId)


    const [itemDetaData, setItemDetailData] = useState(null);
    const navigate = useNavigate();
    
    const viewItemDetail = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewReviewItem?itemCategoryId=${itemCategoryId}&masterCategoryId=${masterCategoryId}&itemId=${itemId}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    // setOffset(offset + 500)
                    // setItemData(response.data)
                    setItemDetailData(response.data[0])
                    // console.log("item detail data", response.data)
                }




            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        viewItemDetail()
    }, [])

    useEffect(() => {
        if (itemId === null) {
            navigate("/ViewItem")
        }
    }, [])

    return (
        <>
            <div className="row">
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px", marginTop: "30px" }}>Review Item</h4>
                    <div className="row" >
                        <div className="col-4">
                            <div className="featureImg" >
                                {itemDetaData && itemDetaData.feature_image}
                            </div>
                            <div className="topimgcontainer" >
                                <div className="reviewImg">
                                    {itemDetaData && itemDetaData.image_1}
                                </div>
                                <div className="reviewImg">
                                    {itemDetaData && itemDetaData.image_2}
                                </div>
                            </div>
                            <div className="topimgcontainer" >
                                <div className="reviewImg">
                                    {itemDetaData && itemDetaData.image_3}
                                </div>
                                <div className="reviewImg">
                                    {itemDetaData && itemDetaData.image_4}                                </div>
                            </div>

                        </div>
                        <div className="col-8" style={{ padding: "2%" }}>
                            <h1>{itemName && itemName}</h1>
                            <table class="table">
                                <thead>
                                    <tr>

                                        <th scope="col">Price</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Approved by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td>{itemDetaData && itemDetaData.mrp}</td>
                                        <td>{itemDetaData && itemDetaData.name}</td>
                                        <td>{itemDetaData && itemDetaData.short_description}</td>
                                        <td>{itemDetaData && itemDetaData.approved_by}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ReviewItem
import SideBar from "../Components/SideBar";
import HomeBack from "../Components/HomeBack";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const ApproveItem = ({ image1, image2, image3, image4 }) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (image3 === null) {
            navigate("/ViewItem")
        }
    }, [])
    return (
        <>
            <div className="row" >
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px", marginTop: "30px" }}>Review Item</h4>
                    <div className="approveItemsFirstContainer">
                        <div>
                            <h3>Image 1</h3>
                            <div className="ApproveImg">
                                {image1 && image1}
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Approve</button>
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Reject</button>
                            </div>
                        </div>
                        <div>
                            <h3>Image 2</h3>
                            <div className="ApproveImg">
                                {image2 && image2}
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Approve</button>
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Reject</button>
                            </div>
                        </div>
                        <div>
                            <h3>Image 3</h3>
                            <div className="ApproveImg">
                                {image3 && image3}
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Approve</button>
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Reject</button>
                            </div>
                        </div>
                        <div>
                            <h3>Image 4</h3>
                            <div className="ApproveImg">
                                {image4 && image4}
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Approve</button>
                            </div>
                            <div>
                                <button className="btn btn-primary  " style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px" }}>Reject</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default ApproveItem;
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Helper from '../Util/Helper';

const ItemList = ({ refreshMerchant, merchantId, setMerchantId }) => {
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();

    // const [offset, setOffset] = useState(0);

    const [loading, setLoading] = useState(false)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [inputText, setInputText] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        // console.log("eeeee", e.target.value)
    };

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalDataforMerchants?contactId=${merchantId}`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        getItem((page - 1) * limit)

    }


    useEffect(() => {
        if (refreshMerchant != null) {

        } else {
            navigate("/ViewMerchants")
        }
    }, [])

    const [firstCol, setFirstCol] = useState(null);
    const [featureCol, setFeatureCol] = useState(null);
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);

    const [itemData, setItemData] = useState(null);
    const [itemRowData, setItemRowData] = useState([]);

    const [check, setCheck] = useState(false);
    const [selectedColIndex, setSelectedColIndex] = useState(null);
    const [indexValue, setIndexValue] = useState(null);

    const onClickEventFunc = (index) => {
        setIndexValue(index);
        setCheck(prevCheck => !prevCheck);
    }

    useEffect(() => {
        if (indexValue && indexValue != null || indexValue === 0) {
            if (check) {
                if (selectedColIndex != null) {
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                }
                setSelectedColIndex(indexValue)
                document.getElementById(`newClass${indexValue}`).style.display = "flex";
            } else {
                if (selectedColIndex === indexValue) {
                    document.getElementById(`newClass${indexValue}`).style.display = "none";
                } else {
                    setSelectedColIndex(indexValue)
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                    document.getElementById(`newClass${indexValue}`).style.display = "flex";
                }
            }
        }
    }, [check, indexValue])

    const getItem = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getItems?inputText=${inputText}&contactId=${merchantId}&limit=${limit}&offset=${offset}`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {


                setItemRowData([])
                // console.log("itemssss", response.data)
                setItemData(response.data)
                if (inputText === "") {
                } else {
                    setTotalFilterData(response.data.length)
                }



            })
            .catch((error) => {
                console.log(error);
            })
    }

    const [totalItemData, setTotalItemData] = useState(0);

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const getItemTotal = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getItemsTotal?contactId=${merchantId}`,
             headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {
                setTotalItemData(response.data[0].count)
                console.log("itemssss total", response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getItemTotal()
    }, [])

    useEffect(() => {
        getItem(0)
    }, [limit, inputText])

    useEffect(() => {
        if (itemData && itemData.length > 0) {
            itemData.map((value, index) => {
                if (!itemRowData.find((el) => el.item_id === value.item_id)) {
                    setItemRowData((prevState) => [
                        ...prevState, {
                            key: index,
                            itemId: value.itemId,
                            blank: <>
                                <th scope="row" style={{ position: "relative" }}>
                                    <AddCircleIcon onClick={() => { setIndexValue(index); onClickEventFunc(index) }} style={{ color: "#3d86c6" }} > </AddCircleIcon>
                                    {/* {showEdit ?  */}
                                    <div className='vutableaddIcon' id={`newClass${index}`}>
                                        <div className='contentContainer'>
                                            <div>
                                                <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default", fontWeight: "800" }} > Active</p>
                                            </div>
                                            <div>
                                                <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default", fontWeight: "300" }} >{value.is_active === 1 ? "Active" : "Not Active"}</p>
                                            </div>
                                        </div>
                                        <div className='line'>

                                        </div>
                                        <div className='contentContainer' onClick={() => { navigate("/UpdateUser"); }}>
                                            <div>
                                                <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default", fontWeight: "800" }} >Action</p>
                                            </div>
                                            <div style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default", display: "flex" }}  >
                                                <CheckIcon fontSize='small' style={{ color: "#3D86C6" }}></CheckIcon>
                                                <BlockIcon fontSize='small' style={{ color: "#3D86C6" }} ></BlockIcon>
                                            </div>
                                        </div>
                                    </div>
                                    {/* : null} */}
                                </th>
                            </>,
                            Name: value.item_name,
                            Desc: value.short_description,
                            Details: value.details,
                            InStock: value.in_stock,
                            Feature:
                                <td><a href={`https://enbfile.s3.ap-south-1.amazonaws.com/${value.contact_id}/itm/${value.item_id}/_original_0.png`} target="_blank" rel="noreferrer"><VisibilityIcon style={{ color: "#3D86C6" }}></VisibilityIcon></a></td>,
                            Image1:
                                <td><a href={`https://enbfile.s3.ap-south-1.amazonaws.com/${value.contact_id}/itm/${value.item_id}/_original_0.png`} target="_blank" rel="noreferrer"><VisibilityIcon style={{ color: "#3D86C6" }}></VisibilityIcon></a></td>,
                            Image2:
                                <td><a href={`https://enbfile.s3.ap-south-1.amazonaws.com/${value.contact_id}/itm/${value.item_id}/_original_1.png`} target="_blank" rel="noreferrer"><VisibilityIcon style={{ color: "#3D86C6" }}></VisibilityIcon></a></td>,
                            Image3:
                                <td><a href={`https://enbfile.s3.ap-south-1.amazonaws.com/${value.contact_id}/itm/${value.item_id}/_original_2.png`} target="_blank" rel="noreferrer"><VisibilityIcon style={{ color: "#3D86C6" }}></VisibilityIcon></a></td>,
                            image4:
                                <td><a href={`https://enbfile.s3.ap-south-1.amazonaws.com/${value.contact_id}/itm/${value.item_id}/_original_3.png`} target="_blank" rel="noreferrer"><VisibilityIcon style={{ color: "#3D86C6" }}></VisibilityIcon></a></td>,
                            Approved: value.is_approved === 0 ? "Not approved" : "approved"
                            ,

                        }
                    ])
                }
            })
        }
    }, [itemData])

    // console.log("item data  :", itemData)


    const data = {
        columns: [
            {
                label: '',
                field: 'blank',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Desc',
                field: 'Desc',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Details',
                field: 'Details',
                sort: 'asc',
                width: 150
            },
            {
                label: 'InStock',
                field: 'InStock',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Feature Image',
                field: 'Feature',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Image 1',
                field: 'Image1',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Image 2',
                field: 'Image2',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Image 3',
                field: 'Image3',
                sort: 'asc',
                width: 100
            }
            ,
            {
                label: 'Image 4',
                field: 'Image4',
                sort: 'asc',
                width: 100
            }
            ,
            {
                label: 'Approved',
                field: 'Approved',
                sort: 'asc',
                width: 100
            }

        ],
        rows: itemRowData && itemRowData,
    }

    // console.log("item row data", itemRowData)
    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>Item List</h4>

                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}
                                />
                                {searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalItemData} entries</p>
                                        <Pagination count={Math.ceil(totalItemData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }

                            </>}

                    </div>
                </div>
            </div>
        </>
    )
}
export default ItemList;
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Helper from '../Util/Helper';

const ViewItem = ({ setImage1, setImage2, setImage3, setImage4, setItemName, itemId, setItemId, itemCategoryId, setItemCategoryId, masterCategoryId, setMasterCategoryId }) => {
    const navigate = useNavigate();
    const [showDateCon, setShowDateCon] = useState(false);
    const [showCal, setShowCal] = useState(false);
    const [todayDate, setTodayDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [moreDetailCol, setMoreDetailCol] = useState(null)
    // const [reviewCol, setReviewCol] = useState(null);

    const [itemData, setItemData] = useState([]);
    const [itemRowData, setItemRowData] = useState([]);

    const [dataStartDate, setDataStartDate] = useState(null);
    const [dataEndDate, setDataEndDate] = useState(null);
    const[pageNumber,setPageNumber]=useState(1);

    // const [pagination, setPagination] = useState({
    //     activePage: 1,
    //     itemsPerPage: 10,
    //   });

    const [loading, setLoading] = useState(false)

    // for paginationClasses

    // const [cusCurrentPage, setCusCurrentPage] = useState(1);
    // const [cusPageSize, setCusPageSize] = useState(10);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    // let limit = 0

    const [totalData, setTotalData] = useState(null);

    // useEffect(() => {
    //     setOffset((cusCurrentPage - 1) * cusPageSize);
    //     setLimit(cusPageSize);
    // }, [cusCurrentPage])

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalData`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    // useEffect(() => {
    //     const element = document.getElementsByClassName("custom-select")[0];
    //     element.addEventListener('change', handleClick);
    // }, [])

    // const handleClick = () => {
    //     const element = document.getElementsByClassName("custom-select")[0];
    //     const elementValue = element.value;
    //     setCusPageSize(elementValue)
    //     console.log("slected value", elementValue)

    // }

    //     const handlePageChange = (page) =>
    //     setPagination({ ...pagination, activePage: page });

    //   const handleEntriesChange = (value) =>
    //     setPagination({ ...pagination, itemsPerPage: value });

    // const handleClickForOffset = (page, size, pageNumber) => {
    //     console.log("changed", page.activePage)
    //     setCusCurrentPage(page.activePage)
    // }

    // console.log("item data", itemData, itemRowData, offset, limit)


    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }

    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page-1)*limit)
        viewItemData((page - 1) * limit)
    }

    const viewItemData = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewItem?LIMIT=${limit}&OFFSET=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    // setOffset(offset + 10)
                    setItemRowData([])
                    setItemData(response.data)
                }
                else {
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const viewItemDataFilter = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewItemFilter?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}`,
        };
        axios(patchItem)
            .then((response) => {
                setItemRowData([])
                setItemData(response.data)
                // console.log("item data", response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (itemData && itemData.length > 0) {
            itemData.map((value, index) => {
                if (!itemRowData.find((ele) => ele.itemId === value.item_id)) {
                    setItemRowData((prevState) => [
                        ...prevState, {
                            itemId: value.item_id,
                            key: index,
                            Name: value.item_name,
                            Desc: value.short_description,
                            Details: value.details,
                            Date: moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'),
                            MoreDetail: <td> <InfoIcon onClick={() => { setItemName(value.item_name); setItemId(value.item_id); setItemCategoryId(value.item_category_id); setMasterCategoryId(value.master_category_id); navigate("/ReviewItem"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>,
                            Review: <td> <InfoIcon onClick={() => { setImage1(value.image_1); setImage2(value.image_2); setImage3(value.image_3); setImage4(value.image_4); navigate("/ApproveItem"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>,
                        }
                    ])
                }
            })
        }
    }, [itemData])

    useEffect(() => {
        if (dataStartDate === null) {
            viewItemData(0)
        } else if (dataStartDate != null) {
            viewItemDataFilter()
        }

    }, [dataStartDate, dataEndDate,limit])
    // cusCurrentPage, cusPageSize, 

    useEffect(() => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
    }, [todayDate])

    const handleEvent1 = (event, picker) => {
        // setStartDate(picker.startDate._d);
        // setEndDate(picker.endDate._d);
        setDataStartDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
        setDataEndDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
    }

    const TodayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const YesterdayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'days'));
        setEndDate(moment(todayDate).subtract(1, 'days'));
        setShowDateCon(false);
        setDataStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    }
    const LastWeek = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(6, 'days'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const LastDays = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'months'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataEndDate(moment().format('YYYY-MM-DD'));
        setDataStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    }
    const ThisMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    }
    const LastMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month').subtract(1, 'months'));
        setEndDate(moment().endOf('month').subtract(1, 'month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
    }

    const data = {
        columns: [


            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Desc',
                field: 'Desc',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Details',
                field: 'Details',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 100
            },
            {
                label: 'More Detail',
                field: 'MoreDetail',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Review',
                field: 'Review',
                sort: 'asc',
                width: 100
            },


        ],
        rows: itemRowData && itemRowData
    }



    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px", marginTop: "30px" }}>View Items</h4>
                    <div className='vmdate' >
                        <input value={startDate && endDate && `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`} readOnly className='vmdateinput' onClick={() => { setShowDateCon(!showDateCon) }}></input>
                        {showDateCon ?
                            <>
                                <div className='dateOptionContainer' >
                                    <div className='dateOption' onClick={() => { TodayFunc() }} >
                                        Today
                                    </div>
                                    <div className='dateOption' onClick={() => { YesterdayFunc() }}>
                                        Yesterday
                                    </div>
                                    <div className='dateOption' onClick={() => { LastWeek() }}>
                                        Last 7 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { LastDays() }} >
                                        Last 30 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { ThisMonth() }}>
                                        This Month
                                    </div>
                                    <div className='dateOption' onClick={() => { LastMonth() }}>
                                        Last Month
                                    </div>
                                    <DateRangePicker onApply={handleEvent1} >
                                        <button type="button" className="dateOption">
                                            Custom Range
                                        </button>
                                    </DateRangePicker>
                                    <div>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmapply' style={{ marginLeft: "5px", marginRight: "5px", color: "white" }}>Apply</button>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmcancel'>Cancel</button>
                                    </div>
                                </div>
                                {showCal ?
                                    <>
                                        <div style={{ position: "absolute", top: "30px", left: "21%" }}>


                                        </div>
                                    </> : null}
                            </> : null}
                    </div>

                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", textAlign: "center",position:"absolute",top:"31%" }}>
                                    <p>show</p>
                                    <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p>entries</p>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                // onPageChange={handleClickForOffset}
                                />
                                <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                    <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                    <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => {handleChangeOffset(page) ;setPageNumber(page)}} variant="outlined" />
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export default ViewItem;

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Header from './Components/Header';
import Footer from './Components/Footer';
import AddUser from './pages/AddUser';
import ViewUser from './pages/ViewUser';
import ViewMerchants from './pages/ViewMerchants';
import ViewSalesPerson from './pages/ViewSalesPerson';
import UpdateUser from './pages/UpdateUser';
import MerchantBillList from './pages/MerchantBillList';
import ItemList from './pages/ItemList.js';
import MerchantDetail from './pages/MerchantDetail';
import SpMerchantDetails from './pages/SpMerchantDetail';
import Attendance from './pages/Attendance';
import Punch from './pages/Punch';
import Telecaller from './pages/Telecaller';
import ViewItem from './pages/ViewItem';
import ReviewItem from './pages/ReviewItem';
import ApproveItem from './pages/ApproveItem';
import RejectItem from './pages/RejectItem';
import SpBillDetail from './pages/SpBillDetail';
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Notification from './pages/Notification';
import { useState ,useEffect} from 'react';
import TestPage from './pages/TestPage';
import ViewCustomer from './pages/ViewCustomer';

function App() {

  const navigate = useNavigate();
  const location = useLocation().pathname

  const [fromMerchant, setFromMerchant] = useState(false)
  const [fromCustomers, setFromCustomer] = useState(false)
  
  const [updateUserNumber,setUpdateUserNumber]=useState(null);
  const[userId,setUserId]=useState(null);
  
  const[merchantId,setMerchantId]=useState(null);

  const[mDetail,setMDetail]=useState(null);

  const[billDetailId,setBillDetailId]=useState(null);

  const[attendanceId,setAttendanceId]=useState(null);
  
  const[itemId,setItemId]=useState(null);
  const[itemCategoryId,setItemCategoryId]=useState(null);
  const[masterCategoryId,setMasterCategoryId]=useState(null);
  const[itemName,setItemName]=useState(null);

 
  const[image1,setImage1]=useState(null);
  const[image2,setImage2]=useState(null);
  const[image3,setImage3]=useState(null);
  const[image4,setImage4]=useState(null);

  const[ifBill,setIfBill]=useState(null);

  const[spId,setSpId]=useState(null);

  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  let isAuthenticated = localStorage.getItem("isAuthentication");
  if(!isAuthenticated){
    isAuthenticated = "false"
  }
  
  // console.log("sjsjjsj",localStorage.getItem("isAuthentication"));
  
  const redirect = (location) => {
    if(['/'].includes(location)) {
      localStorage.setItem("isAuthentication", false)
      navigate('/')
    }
  }

  const[refresh,setRefresh]=useState(null);
  const[refreshMerchant,setRefreshMerchant]=useState(null);
  const[refreshSales,setRefreshSales]=useState(null);

  return (
    <>
      <div style={{ paddingBottom: "50px" }}>
        {/* <Login></Login> */}
        <Header></Header>
        <Routes>
          {isAuthenticated == "false" ? (
            <>
              <Route path="/" element={<Login/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          ) : (
            <>
              {isAuthenticated && redirect(location)}
               <> 
                
                <Route path="/Dashboard" element={<Dashboard fromMerchant={fromMerchant} setFromMerchant={setFromMerchant} fromCustomers={fromCustomers} setFromCustomer={setFromCustomer} />} />
                <Route path="/AddUser" element={<AddUser />} />
                <Route path="/UpdateUser" element={<UpdateUser refresh={refresh} setUserId={setUserId} userId={userId} setUpdateUserNumber={setUpdateUserNumber} updateUserNumber={updateUserNumber}/>} />
                <Route path="/MerchantBillList" element={<MerchantBillList refreshMerchant={refreshMerchant} mDetail={mDetail} setMDetail={setMDetail}  merchantId={merchantId} setMerchantId={setMerchantId}/>} />
                <Route path="/ViewUser" element={<ViewUser setRefresh={setRefresh} refresh={refresh} setUserId={setUserId} userId={userId} setUpdateUserNumber={setUpdateUserNumber} updateUserNumber={updateUserNumber} />} />
                <Route path="/ViewMerchants" element={<ViewMerchants setRefreshMerchant={setRefreshMerchant} mDetail={mDetail} setMDetail={setMDetail} merchantId={merchantId} setMerchantId={setMerchantId} fromMerchant={fromMerchant} setFromMerchant={setFromMerchant} fromCustomers={fromCustomers} setFromCustomer={setFromCustomer} />} />
                <Route path="/ItemList" element={<ItemList refreshMerchant={refreshMerchant} merchantId={merchantId} setMerchantId={setMerchantId}/>} />
                <Route path="/ViewSalesPerson" element={<ViewSalesPerson setRefreshSales={setRefreshSales} setIfBill={setIfBill}  setSpId={setSpId} setAttendanceId={setAttendanceId} attendanceId={attendanceId} billDetailId={billDetailId} setBillDetailId={setBillDetailId}/>} />
                <Route path="/MerchantDetail" element={<MerchantDetail refreshMerchant={refreshMerchant} merchantId={merchantId} setMerchantId={setMerchantId}/>} />
                <Route path="/SpMerchantDetails" element={<SpMerchantDetails ifBill={ifBill}  billDetailId={billDetailId} setBillDetailId={setBillDetailId}/>} />
                <Route path="/SpBillDetail" element={<SpBillDetail ifBill={ifBill}  billDetailId={billDetailId} setBillDetailId={setBillDetailId}/>} />
                <Route path="/Attendance" element={<Attendance refreshSales={refreshSales} setAttendanceId={setAttendanceId} attendanceId={attendanceId} />} />
                <Route path="/Punch" element={<Punch refreshSales={refreshSales} setAttendanceId={setAttendanceId} attendanceId={attendanceId}/>} />
                <Route path="/Telecaller" element={<Telecaller />} />
                <Route path="/ViewCustomer" element={<ViewCustomer setRefreshMerchant={setRefreshMerchant} mDetail={mDetail} setMDetail={setMDetail} merchantId={merchantId} setMerchantId={setMerchantId} fromMerchant={fromMerchant} setFromMerchant={setFromMerchant} fromCustomers={fromCustomers} setFromCustomer={setFromCustomer} />} />
                <Route path="/ViewItem" element={<ViewItem setImage1={setImage1} setImage2={setImage2} setImage3={setImage3} setImage4={setImage4} setItemName={setItemName} itemCategoryId={itemCategoryId} setItemCategoryId={setItemCategoryId} masterCategoryId={masterCategoryId} setMasterCategoryId={setMasterCategoryId} itemId={itemId} setItemId={setItemId}/>} />
                <Route path="/ReviewItem" element={<ReviewItem itemName={itemName} itemCategoryId={itemCategoryId} setItemCategoryId={setItemCategoryId} masterCategoryId={masterCategoryId} setMasterCategoryId={setMasterCategoryId} itemId={itemId} setItemId={setItemId}/>} />
                <Route path="/ApproveItem" element={<ApproveItem image1={image1} image2={image2} image3={image3} image4={image4} />} />
                <Route path="/RejectItem" element={<RejectItem />} />
                <Route path="/TestPage" element={<TestPage />} />
                <Route path="/Notification" element={<Notification />} /></>
              {/* } */}


            </>)}


        </Routes>
      
      </div>
      <div>
        <Footer></Footer>
      </div>


    </>
  );
}

export default App;
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Helper from '../Util/Helper';
import Pagination from '@mui/material/Pagination';


const Punch = ({ setAttendanceId, attendanceId }) => {
    const [punchData, setPunchData] = useState([]);
    const [punchRowData, setPunchRowData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        setFilterClicked(false);
        // console.log("eeeee", e.target.value)
    };

    const handleChangeOffset = (page) => {
        setOffset((page - 1) * limit)
        viewAttendancedata((page - 1) * limit)

    }

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
    }

    const navigate = useNavigate();

    const viewAttendancedata = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewPunch?id=${attendanceId}&inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // if (response.data.length > 0) {
                //     setOffset(offset + 500)
                //     setPunchData(response.data)
                //     // console.log("punch data", response.data)

                // }
                // else {
                //     setLoading(false)
                // }
                // setPunchRowData([])

                setPunchData(response.data)
                setPunchRowData([])
                if (inputText === "") {
                } else {
                    setTotalFilterData(response.data.length)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalPunch?id=${attendanceId}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].total_rows)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])
    useEffect(() => {
        if (attendanceId === null) {
            navigate("/ViewSalesPerson")
        }
    }, [])

    useEffect(() => {
        viewAttendancedata(0)
    }, [limit, inputText])

    useEffect(() => {
        if (punchData && punchData.length > 0) {
            punchData && punchData.map((value, index) => {
                if (!punchRowData.find((ele) => ele.Date === moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'))) {
                    setPunchRowData((punchRowData) => [
                        ...punchRowData, {
                            key: index,
                            Date: moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'),// April 4th 2023, 5:47:02 pm,
                            Punch: value.attandance_type == 0 ? "Punch In" : "punch Out"
                        }
                    ])
                }

            })
        }
    }, [punchData])

    const data = {
        columns: [
            {
                label: 'Date & Time',
                field: 'Date',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Punch Type',
                field: 'Punch',
                sort: 'asc',
                width: 270
            }
        ],
        rows: punchRowData && punchRowData
    }
    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>
                        Punch In Punch Out Details
                    </h4>
                    {loading ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <Skeleton count={10} width={900} height={50} />
                        </div> :
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                        <p>show</p>
                                        <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <p>entries</p>
                                    </div>
                                </div>

                                <div>
                                    <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                </div>
                            </div>
                            <MDBDataTable
                                className='example'
                                striped
                                small
                                data={data}
                                paging={false}
                                searching={false}
                            />
                            {filterClicked || searchClicked ?
                                <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                    <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                    <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                </div> :
                                <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                    <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                    <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                </div>
                            }
                        </>}

                </div>
            </div>
        </>
    )
}

export default Punch;
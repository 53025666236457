import CryptoJS from 'crypto-js'
class Helper {

    static environment = 0;
    static server_base_url = null;
    static console_logger = null;
    static numberValue = null;
    /* 
      environment = 0 (Debugging)
      environment = 1 (Development)
      environment = 2 (Production) 
    */

    static getWsUrl() {
        if (Helper.environment === 0) {
            Helper.server_base_url = "http://159.89.166.21:81";
            // Helper.server_base_url = "http://192.168.1.42:3000";
            Helper.console_logger = 0
        } else if (Helper.environment === 1) {
            Helper.server_base_url = "https://enbill.site";
            // Helper.server_base_url = "http://192.168.1.27:8000/v13";
            // Helper.server_base_url = "http://192.168.1.28:8000/v13";
            Helper.console_logger = 1
        }
        else if (Helper.environment === 1) {
            Helper.server_base_url = "http://192.168.1.54:8000/v14";
            // Helper.server_base_url = "http://192.168.1.28:8000/v13";
            Helper.console_logger = 1
        }
        // http://192.168.1.28:8000/v14

        return Helper.server_base_url
    }

    static consoleLogger = (data) => {
        if (Helper.console_logger === 0) {
            // console.log(data);
        } else if (Helper.console_logger === 1) {
            // console.log(data);
        } else {
            // console.log();
        }
    };



    static encypt_decrypt = (encryptedData) => {
        var key = 'f^Wd2qv8z+gQa%$['//key used in Python
        key = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt(encryptedData, key, { mode: CryptoJS.mode.ECB });
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    }
}

export default Helper;
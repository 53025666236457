import HomeIcon from '@mui/icons-material/Home';
import {useNavigate } from "react-router-dom";
const HomeBack = () =>{
  const navigate = useNavigate();
    return(
        <>
         <div className="row" style={{backgroundColorL:"#fafbfb",width:"100%",height:"5vh",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",width:"100%",margin:"0",marginTop:"20px",marginBottom:"30px"}}>
           <div style={{paddingTop:"25px",backgroundColor:"#fafbfb",display:"flex",borderBottom:"1px solid #e9ebeb"}} >
             <HomeIcon  onClick={() =>{ navigate("/Dashboard");}} style={{color:"#3d86c6",marginRight:"5px",cursor:"pointer"}} fontSize="small"> </HomeIcon>
              <p onClick={() =>{ navigate("/Dashboard");}} style={{color:"#3d86c6",marginTop:"2px",cursor:"pointer"}}>Home</p>
           </div>

         </div>
         
        </>
    )
}
export default HomeBack
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Person from '@mui/icons-material/Person';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';

const SideBar = ({fromMerchant,setFromMerchant,fromCustomers,setFromCustomer}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='d-none d-md-block col-lg-2 col-md-3 sidebar'>
                <div className='sidebarContentWrapper'>
                    <div className='sidebar-heads' style={{ marginTop: "30px" }}>
                        <p style={{ marginBottom: "0" }}>Dashboard</p>
                    </div>
                    <div onClick={() =>{ navigate("/dashboard");}} className='sidebar-subheads'>
                        <HomeIcon style={{ marginRight: "10px" }}></HomeIcon>
                        <div className='content-text'>
                            <p >Dashboard</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Users</p>
                    </div>
                    <div onClick={() =>{ navigate("/AddUser");}} className='sidebar-subheads'>
                        <PersonAddIcon style={{ marginRight: "10px" }}></PersonAddIcon>
                        <div className='content-text'>
                            <p >Add User</p>
                        </div>
                    </div>
                    <hr ></hr>
                    <div  onClick={() =>{ navigate("/ViewUser");}} className='sidebar-subheads' style={{}}>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p>View User</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div  onClick={() =>{ navigate("/ViewMerchants")}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Merchants</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View Merchant</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div  onClick={() =>{ navigate("/ViewCustomer")}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Customer</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View Customer</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                {/* <div  onClick={() =>{ navigate("/ViewMerchants");setFromCustomer(true) ; setFromMerchant(false)}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Customers</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View Customers</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div> */}
                <div  onClick={() =>{ navigate("/ViewSalesPerson");}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Sales Person</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View SalesPerson</p>
                        </div>
                    </div>
                    <hr ></hr>

                </div>
                <div onClick={() =>{ navigate("/Telecaller");}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Telecaller</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <HeadphonesIcon style={{ marginRight: "10px" }}></HeadphonesIcon>
                        <div className='content-text'>
                            <p >Telecaller</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>


                <div  className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Items</p>
                    </div>
                    <div onClick={() =>{ navigate("/ViewItem");}}  className='sidebar-subheads'>
                        <FormatListBulletedIcon style={{ marginRight: "10px" }}></FormatListBulletedIcon>
                        <div className='content-text'>
                            <p >View Items</p>
                        </div>
                    </div>
                    <hr ></hr>
                    {/* <div onClick={() =>{ navigate("/RejectItem");}} className='sidebar-subheads' style={{}}>
                        <FormatListBulletedIcon style={{ marginRight: "10px" }}></FormatListBulletedIcon>
                        <div className='content-text'>
                            <p>Approve/Reject Items</p>
                        </div>
                    </div> */}
                    <hr ></hr>
                </div>

                {/* <div onClick={() =>{ navigate("/Notification");}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Notification</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <NotificationsIcon style={{ marginRight: "10px" }}></NotificationsIcon>
                        <div className='content-text'>
                            <p >Notification</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div> */}


            </div>
        </>
    )
}
export default SideBar
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';

const RejectItem = () => {
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();

    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);
    const [action, setAction] = useState(null);

    useEffect(() => {
        setImage1(
            <div className='rejectImg'></div>
        )
        setImage2(
            <div className='rejectImg'></div>
        )
        setImage3(
            <div className='rejectImg'></div>
        )
        setImage4(
            <div className='rejectImg'></div>
        )
        setAction(
            <button className='btn btn-primary' style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px", margin: "10px", marginTop: "60%" }} >
                Approve
            </button>
        )

    }, [])


    const data = {
        columns: [

            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Image 1',
                field: 'Image1',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Image 2',
                field: 'Image2',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Image 3',
                field: 'Image3',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Image 4',
                field: 'Image4',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Action',
                field: 'Action',
                sort: 'asc',
                width: 100
            },



        ],
        rows: [
            {

                Name: '',
                Image1: image1,
                Image2: image2,
                Image3: image3,
                Image4: image4,
                Action: action,


            },
        ]
    }

    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>
                        Item List
                    </h4>
                    {/* <div className="vuinputs" >
                        <div className='showEntries'>
                            Show <select className='mt-lg-3 mt-1' style={{ padding: "5px", width: "60px", marginLeft: "2px", marginRight: "6px" }}><option>10</option><option>25</option><option>50</option><option>100</option></select>entries
                        </div>
                        <div>
                            search <input></input>
                        </div>
                    </div>
                    <div className='vutable' >
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Image 1</th>
                                    <th scope="col">Image 2</th>
                                    <th scope="col">Image 3</th>
                                    <th scope="col">Image 4</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <th scope="row" style={{ position: "relative" }}>
                                    </th>
                                    <td><div className='rejectImg'></div></td>
                                    <td><div className='rejectImg'></div></td>
                                    <td><div className='rejectImg'></div></td>
                                    <td><div className='rejectImg'></div></td>
                                    <td>
                                        <button className='btn btn-primary' style={{backgroundColor:"#3D86C6",border:"none",borderRadius:"0",fontSize:"13px",padding:"7px 13px",margin:"10px",marginTop:"60%"}} >
                                              Approve
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td><div className='rejectImg'></div></td>
                                    <td>Thornton</td>
                                    <td>@fat</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td><div className='rejectImg'></div></td>
                                    <td>the Bird</td>
                                    <td>@twitter</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                    <div className='vupagination' >
                        <div>
                            <p>Showing 1 to 10 of 224 entries</p>
                        </div>
                    </div> */}

                    <div style={{ padding: "2%" }}>
                        <MDBDataTable
                            className='example'
                            striped
                            small
                            bordered
                            data={data}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default RejectItem;
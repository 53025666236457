import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useEffect, useState } from 'react';
import './Notification.css';
import { color } from '@mui/system';
import { MDBDataTable } from 'mdbreact';


const Notification = () => {
    const [showDateCon, setShowDateCon] = useState(false);
    const [showCal, setShowCal] = useState(false);
    const [todayDate, setTodayDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
    }, [todayDate])

    const handleEvent1 = (event, picker) => {
        setStartDate(picker.startDate._d);
        setEndDate(picker.endDate._d);
    }



    const TodayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
        setShowDateCon(false);
    }
    const YesterdayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'days'));
        setEndDate(moment(todayDate).subtract(1, 'days'));
        setShowDateCon(false);
    }
    const LastWeek = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(6, 'days'));
        setEndDate(todayDate);
        setShowDateCon(false);
    }
    const LastDays = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'months'));
        setEndDate(todayDate);
        setShowDateCon(false);
    }
    const ThisMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        setShowDateCon(false)
    }
    const LastMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month').subtract(1, 'months'));
        setEndDate(moment().endOf('month').subtract(1, 'month'));
        setShowDateCon(false);
    }
    const data = {
        columns: [
            {
                label: 'Merchant Name',
                field: 'MerchantName',
                sort: 'asc',
                width: 150
            },
           

        ],
        rows: [
            {
              
                MerchantName: 'Tiger Nixon',
               
            },
        ]
    }
    return (
        <>
            <div className="row">
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "30px", marginLeft: "20px", marginBottom: "30px" }}>
                        Notification
                    </h4>
                    <div className='vmdate' >

                        <input value={startDate && endDate && `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`} readOnly className='vmdateinput' onClick={() => { setShowDateCon(!showDateCon) }} style={{padding:"8px"}}></input>


                        {showDateCon ?
                            <>
                                <div className='dateOptionContainer' >
                                    <div className='dateOption' onClick={() => { TodayFunc() }} >
                                        Today
                                    </div>
                                    <div className='dateOption' onClick={() => { YesterdayFunc() }}>
                                        Yesterday
                                    </div>
                                    <div className='dateOption' onClick={() => { LastWeek() }}>
                                        Last 7 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { LastDays() }} >
                                        Last 30 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { ThisMonth() }}>
                                        This Month
                                    </div>
                                    <div className='dateOption' onClick={() => { LastMonth() }}>
                                        Last Month
                                    </div>
                                    <DateRangePicker onApply={handleEvent1} >
                                        <button type="button" className="dateOption">
                                            Custom Range
                                        </button>
                                    </DateRangePicker>

                                    <div>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmapply' style={{ marginLeft: "5px", marginRight: "5px", color: "white" }}>Apply</button>
                                        <button className='dateoptionbtn' id='vmcancel'>Cancel</button>
                                    </div>

                                </div>
                                {showCal ?
                                    <>
                                        <div style={{ position: "absolute", top: "30px", left: "21%" }}>


                                        </div>
                                    </> : null}
                            </> : null}



                    </div>
                    <div className='NotificationContainer'>
                        <input className='NotificationHeading' placeholder='Heading'></input>
                        <input className='NotificationHeading' placeholder='Message' style={{ marginTop: "20px" }}></input>
                        <div style={{ width: "100%", border: "1px solid #a2a8a8 ", color: "#a2a8a8 ", marginTop: "20px" }}>
                            <input type="file" className='NotificationHeading' placeholder='Image' accept="image/png, image/gif, image/jpeg"  ></input>
                        </div>
                        <div >
                          <button style={{marginTop:"20px",border:"none"}} className='NotificationHeading' > Send Message</button>
                        </div>
                    </div>
                    <div style={{ paddingLeft: "4%",paddingRight:"4%",paddingTop:"20px" }}>
                    <MDBDataTable
                        className='example'
                        striped
                        small
                        bordered
                        data={data}
                    />
                </div>
                </div>
                

            </div>

        </>
    )
}
export default Notification;
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Helper from '../Util/Helper';

const Telecaller = () => {
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();

    const [tCaller, setTCaller] = useState([]);
    const [tCallerRowData, setTCallerRowData] = useState([]);

    // const [offset, setOffset] = useState(0);

    const [loading, setLoading] = useState(false)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [extraData, setExtraData] = useState(true);
    const [extraTotal, setExtraTotal] = useState(0);


    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);

    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        setFilterClicked(false);
        // console.log("eeeee", e.target.value)
    };


    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalTelecaller`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        viewTeleCaller((page - 1) * limit)

    }

    const viewTeleCaller = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewTeleCaller?inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // setTCallerRowData([])

                // setOffset(offset + 500)
                setTCaller(response.data)
                setTCallerRowData([])
                // console.log("item data", response.data)

                if (inputText === "") {
                    setExtraData(true)
                    // console.log("doneeeeeeeeee")
                } else {
                    setExtraData(false)
                    setExtraTotal(response.data.length)
                    setTotalFilterData(response.data.length)
                }


            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (tCaller && tCaller.length > 0) {
            tCaller.map((value, index) => {
                if (!tCallerRowData.find((ele) => ele.id === value.id)) {
                    setTCallerRowData((tCallerRowData) => [
                        ...tCallerRowData, {
                            key: index,
                            id: value.id,
                            Name: value.name,
                            ProfileType: "Telecaller",
                            AssignTo: '',
                            AssignBy: '',
                            Status: value.is_active === 1 ? "Active" : "Not Active",
                            Date: moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'),// April 4th 2023, 5:47:02 pm,
                            Remark: "",
                            ViewRemark: "",

                        }
                    ])
                }

            })
        }
    }, [tCaller])

    useEffect(() => {
        viewTeleCaller(0)
    }, [limit, inputText])
    const data = {
        columns: [

            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Profile Type',
                field: 'ProfileType',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Assign To',
                field: 'AssignTo',
                sort: 'asc',
                width: 100
            },
            {
                label: 'AssignBy',
                field: 'Assign By',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Status',
                field: 'Status',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Remark',
                field: 'Remark',
                sort: 'asc',
                width: 100
            },
            {
                label: 'View Remark',
                field: 'ViewRemark',
                sort: 'asc',
                width: 100
            },



        ],
        rows: tCallerRowData && tCallerRowData
        // [
        //     {

        //         Name: 'Tiger Nixon',
        //         ProfileType: 'System Architect',
        //         AssignTo: 'Edinburgh',
        //         AssignBy: '61',
        //         Status:'Status',
        //         Date:'Date',
        //         Remark:'Remark',
        //         ViewRemark:'ViewRemark',



        //     },
        // ]
    }

    useEffect(() => {
        if (!searchClicked) {
            setPageNumber(1)
        }
    }, [searchClicked])
    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View Telecaller</h4>
                    {/* <div style={{ paddingLeft: "20px" }}>
                        <button className='btn btn-primary' style={{ backgroundColor: "#3D86C6", border: "none", borderRadius: "0", fontSize: "13px", padding: "7px 13px" }}>
                            Auto assign
                        </button>
                    </div> */}



                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}
                                />
                                {/* {extraData ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <>
                                        {extraTotal > 10 ? <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {extraTotal} of {extraTotal} entries</p>
                                            <Pagination onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div> : <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {extraTotal} entries</p>
                                            <Pagination count={Math.ceil(extraTotal / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div>}
                                    </>

                                } */}
                                {filterClicked || searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination page={pageNumber} count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }

                            </>}

                    </div>
                </div>
            </div>
        </>
    )
}

export default Telecaller;
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Helper from '../Util/Helper';


const ViewMerchants = ({ setRefreshMerchant, mDetail, setMDetail, merchantId, setMerchantId, fromMerchant, setFromMerchant, fromCustomers, setFromCustomer }) => {
    const navigate = useNavigate();
    const [showDateCon, setShowDateCon] = useState(false);
    const [showCal, setShowCal] = useState(false);
    const [todayDate, setTodayDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [limit, setLimit] = useState(100);
    // const [offset, setOffset] = useState(0);

    const [merchantData, setMerchantData] = useState(null);
    const [merchantRowData, setMerchantRowData] = useState([]);

    const [dataStartDate, setDataStartDate] = useState(null);
    const [dataEndDate, setDataEndDate] = useState(null);

    const [moreData, setMoreData] = useState(null);

    const [loading, setLoading] = useState(false)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [extraData, setExtraData] = useState(true);
    const [extraTotal, setExtraTotal] = useState(0);

    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");


    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    useEffect(() => {
        if (dataStartDate && dataEndDate != null) {
            setFilterClicked(true);
        }
    }, [dataStartDate, dataEndDate])



    const handleChangeInput = (e) => {
        ViewMerchantDataFunc(1); setFilterClicked(false); setDataStartDate(null); setStartDate(moment().format('L'));
        setEndDate(moment().format('L'))
        setInputText(e.target.value);
        // console.log("eeeee", e.target.value)
    };

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalDataforMerchants`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])



    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        ViewMerchantDataFunc((page - 1) * limit)

        if (searchClicked) {
        } else if (dataStartDate != null && dataEndDate != null) {
            viewMerchantDataFilterFunc((page - 1) * limit)
        }

    }

    const ViewMerchantDataFunc = (offset) => {
        console.log("odddddd", offset)

        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewMerchantDefault?inputText=${inputText}&limit=${limit}&offset=${offset}`,

        };
        axios(patchItem)
            .then((response) => {

                // setOffset(offset + 500)
                setMerchantRowData([])
                // console.log("merchant response", response.data)
                setMerchantData(response.data)

                if (inputText === "") {
                    setExtraData(true)
                    console.log("doneeeeeeeeee")
                } else {
                    setExtraData(false)
                    // setExtraTotal(response.data.length)
                    setTotalFilterData(response.data.length)
                }


            })
            .catch((error) => {
                console.log(error);
            })


    }

    console.log("filter total data", filterTotalData)

    useEffect(() => {
        setRefreshMerchant(1)
    }, [])

    useEffect(() => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
    }, [todayDate])

    const handleEvent1 = (event, picker) => {
        // setStartDate(picker.startDate._d);
        // setEndDate(picker.endDate._d);
        setDataStartDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
        setDataEndDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
    }

    // const handlePageChange = () => {
    //     setOffset(offset + 500)
    //     ViewMerchantDataFunc()
    // }

    const TodayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const YesterdayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'days'));
        setEndDate(moment(todayDate).subtract(1, 'days'));
        setShowDateCon(false);
        setDataStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    }
    const LastWeek = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(6, 'days'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const LastDays = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'months'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataEndDate(moment().format('YYYY-MM-DD'));
        setDataStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    }
    const ThisMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    }
    const LastMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month').subtract(1, 'months'));
        setEndDate(moment().endOf('month').subtract(1, 'month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
    }

    // const [detailCol, setDetailCol] = useState(null);
    // const [itemCol, setItemCol] = useState(null);
    // const [billCol, setBillCol] = useState(null);
    // const [moreCol, setMoreCol] = useState(null);

    // useEffect(() => {
    //     setDetailCol(
    //         <>
    //             <td> <InfoIcon onClick={() => { navigate("/MerchantDetail"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>
    //         </>
    //     )
    //     setItemCol(
    //         <>
    //             <td> <InfoIcon onClick={() => { navigate("/ItemList"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>
    //         </>
    //     )
    //     setBillCol(
    //         <>
    //             <td> <InfoIcon onClick={() => { navigate("/MerchantBillList"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>
    //         </>
    //     )
    //     setMoreCol(
    //         <>
    //             <AddCircleIcon data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" style={{ backgroundColor: "#3D86C6", color: "white", padding: "2px", borderRadius: "5px" }} > </AddCircleIcon>
    //         </>
    //     )
    // }, [])



    const viewMerchantDataFilterFunc = (offset) => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl() + `/api/getViewMerchantDefault?limit=${limit}&offset=${offset}&dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}`,
            url: Helper.getWsUrl() + `/api/getViewMerchant?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // if (response.data.length > 0) {
                //     setOffset(offset + 500)
                // console.log("merchant responseeeeeeeeeeeeee", response.data.length)
                setMerchantRowData([])
                setMerchantData(response.data)
                // setTotalFilterData(response.data.length)
                // setExtraTotal(response.data.length)
                // setExtraData(false)
                // }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (merchantData && merchantData.length > 0) {
            merchantData.map((value, index) => {
                if (!merchantRowData.find((ele) => ele.contact_id === value.contact_id)) {
                    setMerchantRowData((prevState) => [
                        ...prevState, {
                            key: index,
                            contactId: value.contact_id,
                            Date: moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'),
                            Name: value.name,
                            Phone: value.mobile_number,
                            KYC: value.adhaar_number && value.pan_number != null ? "done" : "pending",
                            Merchant: value.merchant_code,
                            // Profile:value.profile_type_id ,
                            Detail:
                                <td>
                                    <InfoIcon onClick={() => { navigate("/MerchantDetail"); setMerchantId(value.contact_id) }} style={{ color: "#3D86C6" }} >
                                    </InfoIcon>
                                </td>,
                            Items:
                                <td>
                                    <InfoIcon onClick={() => { navigate("/ItemList"); setMerchantId(value.contact_id) }} style={{ color: "#3D86C6" }} >
                                    </InfoIcon>
                                </td>,
                            Bills:
                                <td>
                                    <InfoIcon onClick={() => { navigate("/MerchantBillList"); setMerchantId(value.contact_id); setMDetail(value) }} style={{ color: "#3D86C6" }} >
                                    </InfoIcon>
                                </td>,
                            More:
                                <AddCircleIcon data-bs-toggle="modal" data-bs-target="#exampleModal"
                                    onClick={() => {
                                        // setMerchantId(value.contact_id)
                                        moreFunc(value.contact_id)
                                    }} type="button" style={{ backgroundColor: "#3D86C6", color: "white", padding: "2px", borderRadius: "5px" }} >
                                </AddCircleIcon>,


                        }
                    ])
                }

            })
        }
    }, [merchantData])

    const moreFunc = (id) => {
        // setMerchantId(id)
        // setMId(id)
        viewMerchantMore(id)

    }

    const viewMerchantMore = (id) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/merchantMoreDetail?id=${id}`,
            // url: `http://192.168.1.58:3000/api/getViewMerchantDefault`,
        };
        axios(patchItem)
            .then((response) => {
                // console.log("idddddddddd", id)
                // console.log("more data", response.data)
                setMoreData(response.data[0])
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const data = {
        columns: [

            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Phone',
                field: 'Phone',
                sort: 'asc',
                width: 100
            },
            {
                label: 'KYC',
                field: 'KYC',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Merchant Code',
                field: 'Merchant',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Detail',
                field: 'Detail',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Items',
                field: 'Items',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Bills',
                field: 'Bills',
                sort: 'asc',
                width: 100
            },
            {
                label: 'More',
                field: 'More',
                sort: 'asc',
                width: 100
            }
            ,



        ],
        rows:
            merchantRowData && merchantRowData

    }



    //     useEffect(() => {
    //    ViewMerchantDataFunc()
    //     }, [offset])

    useEffect(() => {
        if (dataStartDate === null) {
            if (inputText === "") {
                ViewMerchantDataFunc((pageNumber - 1) * limit)
            } else {
                ViewMerchantDataFunc(0)
            }

        } else if (dataStartDate != null && !searchClicked) {
            viewMerchantDataFilterFunc(0)
        }
    }, [dataStartDate, dataEndDate, limit, inputText])
    // console.log("merchant row data", merchantRowData)

    const ViewTotalDataFilterFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getviewMerchantTotalFilter?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                setTotalFilterData(response.data[0].count)
                // console.log("count of data", response.data)

            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        ViewTotalDataFilterFunc()
    }, [dataStartDate, dataEndDate])

    console.log("filter total data", filterTotalData);
    console.log("totaldata", totalData)

    // useEffect(() => {
    //     if (!searchClicked) {
    //         setPageNumber(1)
    //     }
    // }, [searchClicked])

    useEffect(() => {
        if (dataStartDate != null) {
            setPageNumber(1)
        }
    }, [dataStartDate])
    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    {/* {fromMerchant ?  <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View Merchants</h4> :  <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View Customers</h4>} */}
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View Merchants</h4>
                    <div className='vmdate' >

                        <input value={startDate && endDate && `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`} readOnly className='vmdateinput' onClick={() => { setShowDateCon(!showDateCon); setExtraData(false); setInputText(""); }}></input>


                        {showDateCon ?
                            <>
                                <div className='dateOptionContainer' >
                                    <div className='dateOption' onClick={() => { TodayFunc() }} >
                                        Today
                                    </div>
                                    <div className='dateOption' onClick={() => { YesterdayFunc() }}>
                                        Yesterday
                                    </div>
                                    <div className='dateOption' onClick={() => { LastWeek() }}>
                                        Last 7 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { LastDays() }} >
                                        Last 30 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { ThisMonth() }}>
                                        This Month
                                    </div>
                                    <div className='dateOption' onClick={() => { LastMonth() }}>
                                        Last Month
                                    </div>
                                    <DateRangePicker onApply={handleEvent1} >
                                        <button type="button" className="dateOption">
                                            Custom Range
                                        </button>
                                    </DateRangePicker>
                                    {/* <div className='dateOption' onClick={() => { setShowCal(!showCal) }}>
                                        Custom Range
                                    </div> */}
                                    <div>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmapply' style={{ marginLeft: "5px", marginRight: "5px", color: "white" }}>Apply</button>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmcancel'>Cancel</button>
                                    </div>

                                </div>
                                {showCal ?
                                    <>
                                        <div style={{ position: "absolute", top: "30px", left: "21%" }}>


                                        </div>
                                    </> : null}
                            </> : null}
                        {/* <DateRangePicker>
                            <button type="button" className="btn btn-primary">
                                click to open
                            </button>
                        </DateRangePicker> */}


                    </div>

                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", 
                                        // top: "31%" 
                                        }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}

                                />
                                {/* {extraData ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <>
                                        {extraTotal > 10 ? <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {extraTotal} of {extraTotal} entries</p>
                                            <Pagination onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div> : <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {extraTotal} entries</p>
                                            <Pagination count={Math.ceil(extraTotal / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div>}
                                    </>

                                } */}
                                {filterClicked || searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination page={pageNumber} count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination page={pageNumber} count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }


                            </>}



                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-body" style={{ padding: "5%" }}>
                                    <table class="table" style={{ border: "1px solid white" }}>
                                        <thead style={{ backgroundColor: "rgb(61, 134, 198)", color: "white" }}>
                                            <tr className='forBorder'>
                                                <th scope="col">Bills</th>
                                                <th scope="col">Transactions</th>
                                                <th scope="col">Items</th>
                                                <th scope="col">GMV</th>
                                                <th scope="col">Cash GMV</th>
                                                <th scope="col">Udhaar GMV</th>
                                                <th scope="col">Online GMV</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: "#4DAF50", color: "white" }}>
                                            <tr className='forBorder'>
                                                <th scope="row">{moreData && moreData.total_bills}</th>
                                                <td>{moreData && moreData.total_transactions}</td>
                                                <td>{moreData && moreData.total_items}</td>
                                                <td>{moreData && moreData.cash_gmv + moreData.udhaar_gmv + moreData.online_gmv}</td>
                                                <td>{moreData && moreData.cash_gmv}</td>
                                                <td>{moreData && moreData.udhaar_gmv}</td>
                                                <td>{moreData && moreData.online_gmv}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewMerchants
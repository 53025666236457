import HomeBack from '../Components/HomeBack';
import SideBar from '../Components/SideBar';
import './ViewSalesPerson.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Helper from '../Util/Helper';

import Pagination from '@mui/material/Pagination';

const SpMerchantDetails = ({ ifBill, billDetailId, setBillDetailId }) => {
    const [merchantDetailData, setMerchantDetailData] = useState([]);
    const [merchantDetailRowData, setMerchantDetailRowData] = useState([]);

    const [offset, setOffset] = useState(0);
    const navigate = useNavigate();


    // console.log("SpMerchantDetails", billDetailId)

    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        setFilterClicked(false);
        // console.log("eeeee", e.target.value)
    };

    // console.log("bill", ifBill)

    const ViewUserDataDetailFunc = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewBillDetail?id=${billDetailId}&inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // setSalesRowData([])
                // setSalesData(response.data)
                // setMoreDetail(response.data[0])
                // if (response.data.length > 0) {
                //     setOffset(offset + 500)
                //     setMerchantDetailData(response.data)

                // }
                // else {
                //     setLoading(false)
                // }
                setMerchantDetailData(response.data)
                setMerchantDetailRowData([])
                if (inputText === "") {
                    // setExtraData(true)
                    // console.log("doneeeeeeeeee")
                } else {
                    // setExtraData(false)
                    // setExtraTotal(response.data.length)
                    setTotalFilterData(response.data.length)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        if (billDetailId === null) {
            navigate("/ViewSalesPerson")
        }
    }, [])

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalSpMd?id=${billDetailId}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].total_rows)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])


    useEffect(() => {
        if (merchantDetailData && merchantDetailData.length > 0) {
            merchantDetailData.map((value, index) => {
                if (!merchantDetailRowData.find((ele) => ele.Date === moment(value.merchant_date).format('MMMM Do YYYY, h:mm:ss a'))) {
                    setMerchantDetailRowData((merchantDetailRowData) => [
                        ...merchantDetailRowData, {
                            key: index,
                            Date: moment(value.merchant_date).format('MMMM Do YYYY, h:mm:ss a'),// April 4th 2023, 5:47:02 pm,
                            Merchant: value.merchant_count,
                        }
                    ])
                }
                // if (!setMerchantDetailRowData.find((ele) => ele.Date === moment(value.merchant_date).format('MMMM Do YYYY, h:mm:ss a'))) {
                //     setMerchantDetailRowData((merchantDetailRowData) => [
                //         ...merchantDetailRowData, {
                //             key: index,
                //             Date: moment(value.merchant_date).format('MMMM Do YYYY, h:mm:ss a'),// April 4th 2023, 5:47:02 pm,
                //             Merchant: value.merchant_count,
                //         }
                //     ])
                // }

            })
        }
    }, [merchantDetailData])



    useEffect(() => {
        ViewUserDataDetailFunc(0)

    }, [limit, inputText])

    const data = {
        columns: [


            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Merchant',
                field: 'Merchant',
                sort: 'asc',
                width: 100
            },



        ],
        rows: merchantDetailRowData && merchantDetailRowData

    }
    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        ViewUserDataDetailFunc((page - 1) * limit)

    }

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }

    return (
        <>
            <div className='row Adduser' style={{ margin: "0", padding: "0" }}>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>
                        SalesPerson Merchant Detail
                    </h4>

                    <div className='spmdContentContainer'>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}
                                />
                                {filterClicked || searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }
                            </>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default SpMerchantDetails;
import './Login.css';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useState, useEffect } from 'react';
import Helper from '../Util/Helper';
import axios from 'axios';
import {useNavigate } from "react-router-dom";

const Login = ({ clientId, setClientId, clientPassword, setClientPassword }) => {
    const navigate = useNavigate();
    const [loginId, setLoginId] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    // const [loginData, setLoginData] = useState("");

    const enterUserName = (e) => {
        setLoginId(e.target.value)
    }
    const enterPassword = (e) => {
        setLoginPassword(e.target.value)
    }
    const submitForm = () => {
       if(loginId === "admin" && loginPassword === "admin"){        
        localStorage.setItem("isAuthentication", true)
        navigate('/Dashboard');
       }else{
        alert("wrong credentials");
       }
    }


    const getBookingChat = () => {
        const patchItem = {
            method: "GET",
            // url: Helper.getWsUrl()() + `/contact/get_super_admin_credential/?web=1`,
             url: Helper.getWsUrl() + `/contact/get_super_admin_credential/?web=1`,
            headers: { "Content-Type": "application/json" },
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.code === 200) {

                    // console.log("chat data ", response.data.data)
                    // console.log("chat data ", Helper.encypt_decrypt(response.data.data))
                } else if (response.data.code === 401) {

                } else {

                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getBookingChat()
    }, [])

    return (
        <div className='row formContainerRow' style={{ position: "absolute", zIndex: "99999999999999999",top:"0" }}>
            <div className='col formContainerWrapper' >
                <div className="formContainer">
                    <p>Login</p>
                    <div className='userNameWrapper' >
                        <PersonIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px" }}></PersonIcon>
                        <input onChange={enterUserName} type="text" placeholder="Enter Username" ></input>
                    </div>
                    <div className='userNameWrapper' >
                        <LockIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px" }}></LockIcon>
                        <input onChange={enterPassword} type="password" placeholder="Enter Password" ></input>
                    </div>

                    <button onClick={() => { submitForm() }}>SIGN IN</button>
                </div>
            </div>
        </div>
    )
}
export default Login



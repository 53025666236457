import HomeBack from '../Components/HomeBack';
import SideBar from '../Components/SideBar';
import './ViewSalesPerson.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Helper from '../Util/Helper';

import Pagination from '@mui/material/Pagination';

const SpBillDetail = ({ ifBill, billDetailId, setBillDetailId }) => {
    const [spBillDetailData, setSpBillDetailData] = useState([]);
    const [spBillDetailRowData, setspBillDetailRowData] = useState([]);
    const [offset, setOffset] = useState(0);

    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        setFilterClicked(false);
        // console.log("eeeee", e.target.value)
    };


    const [loading, setLoading] = useState(false)




    // console.log("SpMerchantDetails", billDetailId)

    // console.log("bill", ifBill)

    const navigate = useNavigate();
    useEffect(() => {
        if (billDetailId === null) {
            navigate("/ViewSalesPerson")
        }
    }, [])
    const ViewUserDataBill = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewBillDetailForBill?id=${billDetailId}&inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // if (response.data.length > 0) {
                //     // setOffset(offset + 500)
                //     setSpBillDetailData(response.data)
                //     // console.log("bill response", response.data)
                // } else {
                //     setLoading(false)
                // }
                setSpBillDetailData(response.data)
                setspBillDetailRowData([])
                if (inputText === "") {
                } else {
                    setTotalFilterData(response.data.length)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalspBillDetail?id=${billDetailId}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].total_rows)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    useEffect(() => {
        if (spBillDetailData && spBillDetailData.length > 0) {
            spBillDetailData.map((value, index) => {
                if (!spBillDetailRowData.find((ele) => ele.Date === moment(value.bill_date).format('MMMM Do YYYY, h:mm:ss a'))) {
                    setspBillDetailRowData((spBillDetailRowData) => [
                        ...spBillDetailRowData, {
                            key: index,
                            Date: moment(value.bill_date).format('MMMM Do YYYY, h:mm:ss a'),// April 4th 2023, 5:47:02 pm,
                            Bills: value.bill_count,
                        }
                    ])
                }
            })
        }
    }, [spBillDetailData])



    useEffect(() => {
        ViewUserDataBill(0)
        // ViewUserDataBill()
    }, [limit, inputText])

    const data = {
        columns: [
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Bills',
                field: 'Bills',
                sort: 'asc',
                width: 100
            },
        ],
        rows: spBillDetailRowData && spBillDetailRowData
    }

    const handleChangeOffset = (page) => {
        setOffset((page - 1) * limit)
        ViewUserDataBill((page - 1) * limit)

    }

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
    }

    return (
        <>
            <div className='row Adduser' style={{ margin: "0", padding: "0" }}>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>
                        SalesPerson Bill Detail
                    </h4>

                    <div className='spmdContentContainer'>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>
                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}
                                />
                                {filterClicked || searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }
                            </>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default SpBillDetail;
import LogoutIcon from '@mui/icons-material/Logout';
import Person from '@mui/icons-material/Person';
import './Header.css'
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];


const Header = (props) => {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                MUI
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const logoutFunc = () => {
        navigate('/')
        localStorage.setItem("isAuthentication",false)
    }

    return (
        <>
            return (
            <Box sx={{ display: 'flex', }}>
                <CssBaseline />
                <AppBar component="nav" className='headerRow' >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >

                        </Typography>
                        <Box sx={{ display: { xs: 'block', sm: 'block'} , position : {xs:'absolute',sm:'relative'}, right : {xs:'15px'}}}>
                            <div class="dropdown">
                                <Person data-bs-toggle="dropdown" fontSize='large' style={{ color: "white" }}> </Person>
                                <ul class="dropdown-menu">
                                    <div onClick={() => {logoutFunc()}} style={{ display: "flex", padding: "5%", justifyContent: "center", alignItems: "center" }} >
                                        <p style={{ marginTop: "15px", marginRight: "5px" }}>Admin Logout</p>
                                        <LogoutIcon></LogoutIcon>
                                    </div>
                                </ul>
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                       <div className='sidebarContentWrapper'>
                    <div className='sidebar-heads' style={{ marginTop: "30px" }}>
                        <p style={{ marginBottom: "0" }}>Dashboard</p>
                    </div>
                    <div onClick={() =>{ navigate("/Dashboard");}}  className='sidebar-subheads'>
                        <HomeIcon style={{ marginRight: "10px" }}></HomeIcon>
                        <div className='content-text'>
                            <p >Dashboard</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Users</p>
                    </div>
                    <div onClick={() =>{ navigate("/AddUser");}} className='sidebar-subheads'>
                        <PersonAddIcon style={{ marginRight: "10px" }}></PersonAddIcon>
                        <div className='content-text'>
                            <p >Add User</p>
                        </div>
                    </div>
                    <hr ></hr>
                    <div  onClick={() =>{ navigate("/ViewUser");}} className='sidebar-subheads' style={{}}>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p>View User</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div onClick={() =>{ navigate("/ViewMerchants");}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Merchants</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View Merchant</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div onClick={() =>{ navigate("/ViewSalesPerson");}} className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Sales Person</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <Person style={{ marginRight: "10px" }}></Person>
                        <div className='content-text'>
                            <p >View SalesPerson</p>
                        </div>
                    </div>
                    <hr ></hr>

                </div>
                <div className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Telecaller</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <HeadphonesIcon style={{ marginRight: "10px" }}></HeadphonesIcon>
                        <div className='content-text'>
                            <p >Telecaller</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                <div className='sidebarContentWrapper' style={{ marginTop: "-10px" }}>
                    <div className='sidebar-heads' style={{}}>
                        <p style={{ marginBottom: "0" }}>Items</p>
                    </div>
                    <div className='sidebar-subheads'>
                        <FormatListBulletedIcon style={{ marginRight: "10px" }}></FormatListBulletedIcon>
                        <div className='content-text'>
                            <p >View Items</p>
                        </div>
                    </div>
                    <hr ></hr>
                    <div className='sidebar-subheads' style={{}}>
                        <FormatListBulletedIcon style={{ marginRight: "10px" }}></FormatListBulletedIcon>
                        <div className='content-text'>
                            <p >Approve/Reject Items</p>
                        </div>
                    </div>
                    <hr ></hr>
                </div>
                    </Drawer>
                </Box>

            </Box>
            );

            {/* <div className='row headerRow'  >
                <div className='col d-block d-md-none' >
                    <div className='dropdown2'>
                        <MenuIcon data-bs-toggle="dropdown2" fontSize='large' style={{ color: "white", position: "absolute", top: "20px", left: "20px" }}></MenuIcon>
                        <ul class="dropdown-menu">
                            <div style={{ display: "flex", padding: "5%", justifyContent: "center", alignItems: "center" }} >
                                <p style={{ marginTop: "15px", marginRight: "5px" }}>Admin Logout</p>
                                <LogoutIcon></LogoutIcon>
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='col'>
                    <div class="dropdown">
                        <Person data-bs-toggle="dropdown" fontSize='large' style={{ position: "absolute", top: "20px", right: "20px", color: "white" }}> </Person>
                        <ul class="dropdown-menu">
                            <div style={{ display: "flex", padding: "5%", justifyContent: "center", alignItems: "center" }} >
                                <p style={{ marginTop: "15px", marginRight: "5px" }}>Admin Logout</p>
                                <LogoutIcon></LogoutIcon>
                            </div>
                        </ul>
                    </div>
                </div>

            </div> */}
        </>
    )
}
export default Header;
import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Helper from '../Util/Helper';
import Pagination from '@mui/material/Pagination';

const MerchantBillList = ({ refreshMerchant, mDetail, setMDetail, merchantId, setMerchantId }) => {
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();

    const [billData, setBillData] = useState(null);
    const [billRowData, setBillRowData] = useState([]);
    const [cDetail, setCDetail] = useState(null);
    const [billId, setBillId] = useState(null);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [inputText, setInputText] = useState("");
    const [totalItemData, setTotalItemData] = useState(0);

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        // console.log("eeeee", e.target.value)
    };

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        getBills((page - 1) * limit)

    }
    const getItemTotal = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getTotalMbill?contactId=${merchantId}`,
        };
        axios(patchItem)
            .then((response) => {
                setTotalItemData(response.data[0].count)
                console.log("itemssss total", response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getItemTotal()
    }, [])




    useEffect(() => {
        if (refreshMerchant != null) {

        } else {
            navigate("/ViewMerchants")
        }
    }, [])

    useEffect(() => {
        if (billData && billData.length > 0) {
            billData.map((value, index) => {
                contactDetail(value.customer_id, value.bill_id)
                getBillLink(value.bill_id)
                if (!billRowData.find(element => element.BillId === value.bill_id)) {
                    setBillRowData((billRowData) => [
                        ...billRowData, {
                            key: index,
                            Invoice: value.bill_number,
                            Merchant: mDetail && mDetail.name,
                            MerchantPh: mDetail && mDetail.mobile_number,
                            Customer: cDetail && cDetail.name,
                            CustomerPh: cDetail && cDetail.mobile_number,
                            Status: value.status,
                            Amount: value.total_amount,
                            Date: value.add_date_time,
                            BillId: value.bill_id
                            // View:
                            //    value
                            //    ,
                        }
                    ])
                }
            })
        }
    }, [billData])



    const contactDetail = (c_id, b_id) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewContact?c_id=${c_id}&b_id=${b_id}`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.result.length > 0) {
                    setCDetail(response.data.result[0]);
                }
                setBillId(response.data.b_id)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getBillLink = (b_id) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/get_bill_link?bill_id=${b_id}`,
        };
        axios(patchItem)
            .then((response) => {
                // console.log("repspopmvmnvs :", response)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const data = {
        columns: [
            {
                label: 'Invoice Number',
                field: 'Invoice',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Merchant Name',
                field: 'Merchant',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Merchant Ph',
                field: 'MerchantPh',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Customer Name',
                field: 'Customer',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Customer Ph',
                field: 'CustomerPh',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Status',
                field: 'Status',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Amount',
                field: 'Amount',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 100
            },
            {
                label: 'View Bill',
                field: 'View',
                sort: 'asc',
                width: 100
            },
        ],
        rows: billRowData && billRowData
    }

    const getBills = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getBills?inputText=${inputText}&contactId=${merchantId}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                setBillRowData([])
                setBillData(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getBills(0)
    }, [limit, inputText])

    useEffect(() => {
        if (billId && billId != null) {
            if (billRowData && billRowData.find((value) => value.BillId === billId)) {
                let indexArr;
                let newArray = [...billRowData];
                let newRow = newArray.filter((value, index) => {
                    if (value.BillId === billId) {
                        indexArr = index
                        return value
                    }
                })
                newRow[0].Customer = cDetail.name;
                newRow[0].CustomerPh = cDetail.mobile_number;
                newArray[indexArr] = newRow[0];
                setBillRowData(newArray)
            }
        }
    }, [billId])

    // console.log("bill data : ", billData)

    return (
        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>
                        Merchant Bill List
                    </h4>

                    <div style={{ padding: "2%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                    <p>show</p>
                                    <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <p>entries</p>
                                </div>
                            </div>

                            <div>
                                <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                            </div>
                        </div>
                        <MDBDataTable
                            className='example'
                            striped
                            small
                            data={data}
                            paging={false}
                            searching={false}
                        />
                        <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                            <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalItemData} entries</p>
                            <Pagination count={Math.ceil(totalItemData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MerchantBillList
import '../pages/ViewSalesPerson';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import axios from 'axios';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';
import Helper from '../Util/Helper';


const ViewSalesPerson = ({ setIfBill, setSpId, setBillDetailId, billDetailId, attendanceId, setAttendanceId }) => {
    const [showDateCon, setShowDateCon] = useState(false);
    const [todayDate, setTodayDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();
    const [dataStartDate, setDataStartDate] = useState(null);
    const [dataEndDate, setDataEndDate] = useState(null);

    const [assignData, setAssignData] = useState(null);

    const [loading, setLoading] = useState(false)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");

    const [extraData, setExtraData] = useState(true);
    const [extraTotal, setExtraTotal] = useState(0)

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    useEffect(() => {
        if (dataStartDate && dataEndDate != null) {
            setFilterClicked(true);
        }
    }, [dataStartDate, dataEndDate])

    const handleChangeInput = (e) => {
        ViewUserDataFunc(1); setFilterClicked(false); setDataStartDate(null); setStartDate(moment().format('L'));
        setEndDate(moment().format('L'))
        setInputText(e.target.value);
        // console.log("eeeee",e.target.value)
    };

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalDataforUserSp`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        ViewUserDataFunc((page - 1) * limit)

        if (searchClicked) {
        } else if (dataStartDate != null && dataEndDate != null) {
            ViewUserDataFilterFunc((page - 1) * limit)
        }

    }


    useEffect(() => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
    }, [todayDate])

    const handleEvent1 = (event, picker) => {
        // setStartDate(picker.startDate._d);
        // setEndDate(picker.endDate._d);
        setDataStartDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
        setDataEndDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
    }
    const TodayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const YesterdayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'days'));
        setEndDate(moment(todayDate).subtract(1, 'days'));
        setShowDateCon(false);
        setDataStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    }
    const LastWeek = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(6, 'days'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const LastDays = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'months'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataEndDate(moment().format('YYYY-MM-DD'));
        setDataStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    }
    const ThisMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    }
    const LastMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month').subtract(1, 'months'));
        setEndDate(moment().endOf('month').subtract(1, 'month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
    }


    const [salesData, setSalesData] = useState([]);
    const [salesRowData, setSalesRowData] = useState([]);

    const [check, setCheck] = useState(false);
    const [indexValue, setIndexValue] = useState(null);
    const [selectedColIndex, setSelectedColIndex] = useState(null);
    const [showCal, setShowCal] = useState(false);
    // const [offset, setOffset] = useState(0);
    const [moreDetail, setMoreDetail] = useState(null);



    const onClickEventFunc = (index) => {
        setIndexValue(index);
        setCheck(prevCheck => !prevCheck);
    }

    useEffect(() => {
        if (indexValue && indexValue != null || indexValue === 0) {
            if (check) {
                if (selectedColIndex != null) {
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                }
                setSelectedColIndex(indexValue)
                document.getElementById(`newClass${indexValue}`).style.display = "flex";
            } else {
                if (selectedColIndex === indexValue) {
                    document.getElementById(`newClass${indexValue}`).style.display = "none";
                } else {
                    setSelectedColIndex(indexValue)
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                    document.getElementById(`newClass${indexValue}`).style.display = "flex";
                }
            }
        }
    }, [check, indexValue])

    const ViewUserDataFunc = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewSalesDefault?inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                setSalesRowData([])

                // setOffset(offset + 500)
                setSalesData(response.data)
                // console.log("for id", response.data)
                setAssignData(response.data)

                if (inputText === "") {
                    setExtraData(true)
                    console.log("doneeeeeeeeee")
                } else {
                    setExtraData(false)
                    // setExtraTotal(response.data.length)
                    setTotalFilterData(response.data.length)
                }


            })
            .catch((error) => {
                console.log(error);
            })
    }

    const ViewUserDataDetailFunc = (id) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewSalesDetail?id=${id}`,
        };
        axios(patchItem)
            .then((response) => {
                // setSalesRowData([])
                // setSalesData(response.data)
                setMoreDetail(response.data[0])
                // console.log("idddd response", response.data)

            })
            .catch((error) => {
                console.log(error);
            })
    }





    const ViewUserDataFilterFunc = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getviewSales?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                setSalesRowData([])
                setSalesData(response.data)
                // setTotalFilterData(response.data.length)
                // setExtraTotal(response.data.length)
                // setExtraData(false)

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (dataStartDate === null) {
            if (inputText === "") {
                ViewUserDataFunc((pageNumber - 1) * limit)
            } else {
                ViewUserDataFunc(0)
            }

        } else if (dataStartDate != null && !searchClicked) {
            ViewUserDataFilterFunc(0)
        }
    }, [dataStartDate, dataEndDate, limit, inputText])



    // console.log("assign data", assignData)

    useEffect(() => {
        if (salesData && salesData.length > 0) {
            salesData && salesData.map((value, index) => {

                if (!salesRowData.find((ele) => ele.id === value.id)) {
                    setSalesRowData((salesRowData) => [
                        ...salesRowData, {
                            key: index,
                            id: value.id,
                            blank: <th scope="row" style={{ position: "relative" }}>
                                <AddCircleIcon onClick={() => { setIndexValue(index); onClickEventFunc(index) }} style={{ color: "#3d86c6" }} > </AddCircleIcon>
                                <div className='vutableaddIcon' id={`newClass${index}`}>
                                    <div className='contentContainer' onClick={() => { setAttendanceId(value.id); navigate("/Attendance"); }}>
                                        <div>
                                            <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Attendance</p>
                                        </div>
                                        <div>
                                            <InfoIcon fontSize='small' style={{ color: "rgb(61, 134, 198)" }}></InfoIcon>
                                        </div>
                                    </div>
                                    <div className='line'>

                                    </div>
                                    <div className='contentContainer' onClick={() => { setAttendanceId(value.id); navigate("/Punch"); }}>
                                        <div>
                                            <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Punch</p>
                                        </div>
                                        <div>
                                            <InfoIcon fontSize='small' style={{ color: "rgb(61, 134, 198)" }}></InfoIcon>
                                        </div>

                                    </div>

                                </div>

                            </th>,
                            Date: moment(value.add_date_time).format('MMMM Do YYYY, h:mm:ss a'),
                            UserName: "",
                            Name: value.name,
                            Phone: value.mobile_number,
                            AssignTo:
                                <td>
                                    <select className='vspDropdown' style={{ width: "300px" }}>
                                        <option style={{ display: "none" }}>Assign</option>
                                        {assignData && assignData.map((value) => (
                                            <option>{value.name}</option>
                                        ))}
                                    </select>
                                </td>,
                            More:
                                <td>
                                    <div>
                                        <AddCircleIcon onClick={() => { ViewUserDataDetailFunc(value.id) }} data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" style={{ backgroundColor: "#3D86C6", color: "white", padding: "2px", borderRadius: "5px" }} > </AddCircleIcon>
                                    </div>
                                </td>,
                            MDetails:
                                <td> <InfoIcon onClick={() => { setIfBill(0); setBillDetailId(value.id); navigate('/SpMerchantDetails') }} style={{ color: "#3D86C6" }} ></InfoIcon></td>,
                            BDetails:
                                <td> <InfoIcon onClick={() => { setIfBill(1); setBillDetailId(value.id); navigate('/SpBillDetail') }} style={{ color: "#3D86C6" }} ></InfoIcon></td>,
                            Onboarding: "",


                        }
                    ])
                }


            })
        }
    }, [salesData])

    // console.log("bill id ", billDetailId)



    // useEffect(() => {
    //     setFirstCol(
    //         <>
    //             <th scope="row" style={{ position: "relative" }}>
    //                 <AddCircleIcon onClick={() => { setShowEdit(!showEdit) }} style={{ color: "#3d86c6" }} > </AddCircleIcon>
    //                 {showEdit ? <div className='vutableaddIcon' >
    //                     <div className='contentContainer' onClick={() => { navigate("/Attendance"); }}>
    //                         <div>
    //                             <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Attendance</p>
    //                         </div>
    //                         <div>
    //                             <InfoIcon fontSize='small' style={{ color: "rgb(61, 134, 198)" }}></InfoIcon>
    //                         </div>
    //                     </div>
    //                     <div className='line'>

    //                     </div>
    //                     <div className='contentContainer' onClick={() => { navigate("/Punch"); }}>
    //                         <div>
    //                             <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Punch</p>
    //                         </div>
    //                         <div>
    //                             <InfoIcon fontSize='small' style={{ color: "rgb(61, 134, 198)" }}></InfoIcon>
    //                         </div>

    //                     </div>

    //                 </div> : null}

    //             </th>
    //         </>
    //     )
    //     setAssign(
    //         <td>
    //             <select className='vspDropdown' style={{ width: "300px" }}>
    //                 <option style={{ display: "none" }}>Assign</option>
    //                 <option>1</option>
    //                 <option>1</option>
    //                 <option>1</option>
    //                 <option>1</option>
    //             </select>
    //         </td>
    //     )
    //     setMore(
    //         <td>
    //             <div>
    //                 <AddCircleIcon data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" style={{ backgroundColor: "#3D86C6", color: "white", padding: "2px", borderRadius: "5px" }} > </AddCircleIcon>
    //             </div>
    //         </td>
    //     )
    //     setDetailCol(
    //         <td> <InfoIcon onClick={() => { navigate("/SpMerchantDetails"); }} style={{ color: "#3D86C6" }} ></InfoIcon></td>
    //     )

    // }, [showEdit])

    const data = {
        columns: [
            {
                label: '',
                field: 'blank',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 270
            },
            {
                label: 'UserName',
                field: 'UserName',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Phone',
                field: 'Phone',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Assign To',
                field: 'AssignTo',
                sort: 'asc',
                width: 100
            },
            {
                label: 'More',
                field: 'More',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Merchant Details',
                field: 'MDetails',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Bill Details',
                field: 'BDetails',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Onboarding Location',
                field: 'Onboarding',
                sort: 'asc',
                width: 100
            },



        ],
        rows:
            salesRowData && salesRowData
        // {
        //     blank: firstCol,
        //     Date: 'Tiger Nixon',
        //     UserName: 'System Architect',
        //     Name: 'Edinburgh',
        //     Phone: '61',
        //     AssignTo: assign,
        //     More: more,
        //     Details: detailCol,
        //     Onboarding:'new delhi'

        // },

    }

    const ViewTotalDataFilterFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getviewSpTotalFilter?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                setTotalFilterData(response.data[0].count)
                // console.log("count of data", response.data)

            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        if (dataStartDate != null) {
            ViewTotalDataFilterFunc()
        }
    }, [dataStartDate, dataEndDate])

    // useEffect(() => {
    //     if (!searchClicked) {
    //         setPageNumber(1)
    //     }
    // }, [searchClicked])

    useEffect(() => {
        if (dataStartDate != null) {
            setPageNumber(1)
        }
    }, [dataStartDate])

    return (

        <>
            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View SalesPerson</h4>
                    <div className='vmdate' >
                        <input value={startDate && endDate && `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`} readOnly className='vmdateinput' onClick={() => { setShowDateCon(!showDateCon); setExtraData(false); setInputText(""); }}></input>
                        {showDateCon ?
                            <>
                                <div className='dateOptionContainer'>
                                    <div className='dateOption' onClick={() => { TodayFunc() }} >
                                        Today
                                    </div>
                                    <div className='dateOption' onClick={() => { YesterdayFunc() }}>
                                        Yesterday
                                    </div>
                                    <div className='dateOption' onClick={() => { LastWeek() }}>
                                        Last 7 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { LastDays() }} >
                                        Last 30 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { ThisMonth() }}>
                                        This Month
                                    </div>
                                    <div className='dateOption' onClick={() => { LastMonth() }}>
                                        Last Month
                                    </div>
                                    <DateRangePicker onApply={handleEvent1} >
                                        <button type="button" className="dateOption">
                                            Custom Range
                                        </button>
                                    </DateRangePicker>
                                    <div>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmapply' style={{ marginLeft: "5px", marginRight: "5px", color: "white" }}>Apply</button>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmcancel'>Cancel</button>
                                    </div>
                                </div>
                                {showCal ?
                                    <>
                                        <div style={{ position: "absolute", top: "30px", left: "21%" }}>
                                        </div>
                                    </>
                                    : null}
                            </>
                            : null}
                    </div>

                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{
                                            display: "flex", textAlign: "center", position: "absolute",
                                            // top: "31%" 
                                        }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>
                                </div>

                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    data={data}
                                    paging={false}
                                    searching={false}
                                />
                                {filterClicked || searchClicked ?
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                        <Pagination page={pageNumber} count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div> :
                                    <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                        <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                        <Pagination page={pageNumber} count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                    </div>
                                }

                            </>}


                    </div>
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document" >
                            <div class="modal-content" style={{ width: "fitContent" }}>
                                <div class="modal-body" style={{ padding: "5%" }}>
                                    <table class="table" style={{ border: "1px solid white" }}>
                                        <thead style={{ backgroundColor: "rgb(61, 134, 198)", color: "white" }}>
                                            <tr className='forBorder'>
                                                <th scope="col">Merchants</th>
                                                <th scope="col">Bills</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: "#4DAF50", color: "white" }}>
                                            <tr className='forBorder'>
                                                <th scope="row">{moreDetail && moreDetail.total_merchant}</th>
                                                <td>{moreDetail && moreDetail.total_bills}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ViewSalesPerson;
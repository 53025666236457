import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MDBDataTable } from 'mdbreact';
import { useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Helper from '../Util/Helper';
import Pagination from '@mui/material/Pagination';

const Attendance = ({ attendanceId, setAttendanceId }) => {
    const [showEdit, setShowEdit] = useState(false);
    const navigate = useNavigate();

    const [attendanceData, setAttendanceData] = useState([]);
    const [attendanceRowData, setAttendanceRowData] = useState([]);

    const [punchOut, setPunchOut] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);
    const [inputText, setInputText] = useState("");

    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    const handleChangeInput = (e) => {
        setInputText(e.target.value);
        setFilterClicked(false);
        // console.log("eeeee", e.target.value)
    };

    const handleChangeOffset = (page) => {
        setOffset((page - 1) * limit)
        viewAttendancedata((page - 1) * limit)

    }

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
    }



    const viewAttendancedata = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/viewAttendance?id=${attendanceId}&inputText=${inputText}&limit=${limit}&offset=${offset}`,
            headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {
                // if (response.data.length > 0) {
                //     setOffset(offset + 500)
                //     setAttendanceData(response.data)
                //     // console.log("atttt data", response.data)
                // }
                // else {
                //     setLoading(false)
                // }
                // setAttendanceRowData([])
                setAttendanceData(response.data)
                setAttendanceRowData([])
                if (inputText === "") {
                } else {
                    setTotalFilterData(response.data.length)
                }

            })
            .catch((error) => {

                console.log(error);

            })
    }

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalAttendance?id=${attendanceId}`, headers: {
                "Content-Type": "application/json", 
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
              }
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].total_rows)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    useEffect(() => {
        if (attendanceId === null) {
            navigate("/ViewSalesPerson")
        }
    }, [])
    // const viewAttendancedataPunchOut = () => {
    //     const patchItem = {
    //         method: "GET",
    //         url: Helper.getWsUrl() + `/api/viewAttendancePunchOut?id=${attendanceId}`,
    //     };
    //     axios(patchItem)
    //         .then((response) => {
    //             // setRowData([])
    //             // setViewUserData(response.data)
    //             // setAttendanceRowData([])
    //             setPunchOut(response.data)
    //             console.log("atttt punch out",response.data)

    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }


    useEffect(() => {
        viewAttendancedata(0)
        // viewAttendancedataPunchOut()
    }, [limit, inputText])

    useEffect(() => {
        if (attendanceData && attendanceData.length > 0) {
            attendanceData.map((value, index) => {

                if (!attendanceRowData.find((ele) => ele.Date === value.date.slice(0, 10))) {
                    setAttendanceRowData((attendanceRowData) => [
                        ...attendanceRowData, {
                            key: index,
                            Date: value.date.slice(0, 10),
                            punchin: value.punchIn,
                            punchout: value.punchOut,
                        }
                    ])
                }

                // setAttendanceRowData((attendanceRowData) => [
                //     ...attendanceRowData, {
                //         key: index,
                //         Date: value.date.slice(0, 10),
                //         punchin: value.punchIn,
                //         punchout: value.punchOut,
                //     }
                // ])
            })
        }
    }, [attendanceData])

    // console.log("punch out",punchOut);

    // useEffect(()=>{
    //     if(punchOut.length > 0 && attendanceRowData.length > 0){
    //         let array = [...attendanceRowData]
    //         punchOut.map((val) => {
    //             let index
    //             let arrayVar = array.filter((ele,ind)=> {
    //                 if(ele.Date === val.date.slice(0,10)) {
    //                     index = ind
    //                     return ele
    //                 }
    //             })
    //             arrayVar[0].punchout = val.countOut
    //             array[index] = arrayVar[0]           
    //         })
    //         setAttendanceRowData(array)
    //     }
    // },[punchOut,attendanceRowData])






    const data = {
        columns: [
            {
                label: 'Date',
                field: 'Date',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Total Punch In',
                field: 'punchin',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Total Punch out',
                field: 'punchout',
                sort: 'asc',
                width: 200
            },




        ],
        rows:
            attendanceRowData && attendanceRowData
    }
    return (
        <>

            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>Attendance</h4>
                    {loading ?
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <Skeleton count={10} width={900} height={50} />
                        </div> :
                        <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <div style={{ display: "flex", textAlign: "center", position: "absolute", top: "25%" }}>
                                        <p>show</p>
                                        <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        <p>entries</p>
                                    </div>
                                </div>

                                <div>
                                    <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                </div>
                            </div>
                            <MDBDataTable
                                className='example'
                                striped
                                small
                                data={data}
                                paging={false}
                                searching={false}
                            />
                            {filterClicked || searchClicked ?
                                <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                    <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                    <Pagination count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                </div> :
                                <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                    <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                    <Pagination count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                </div>
                            }

                        </>}

                </div>
            </div>
        </>

    )
}
export default Attendance;
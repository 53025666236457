import './ViewUser.css';
import SideBar from '../Components/SideBar';
import HomeBack from '../Components/HomeBack';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import Helper from '../Util/Helper';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Pagination from '@mui/material/Pagination';

import { MDBDataTable } from 'mdbreact';
const ViewUser = ({ setRefresh, refresh, userId, setUserId, setUserIdupdateUserNumber, setUpdateUserNumber }) => {

    const [viewUserData, setViewUserData] = useState([]);
    const [rowData, setRowData] = useState([]);

    const [selectedColIndex, setSelectedColIndex] = useState(null);
    const [indexValue, setIndexValue] = useState(null);
    const navigate = useNavigate();

    const [showDateCon, setShowDateCon] = useState(false);
    const [showCal, setShowCal] = useState(false);
    const [todayDate, setTodayDate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [check, setCheck] = useState(false);
    const [delId, setDelId] = useState(null);

    // pagination


    const [dataStartDate, setDataStartDate] = useState(null);
    const [dataEndDate, setDataEndDate] = useState(null);

    // const [offset, setOffset] = useState(0);

    const [loading, setLoading] = useState(false)

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [totalData, setTotalData] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [inputText, setInputText] = useState("");

    const [extraData, setExtraData] = useState(true);

    const [filterClicked, setFilterClicked] = useState(false);
    const [filterTotalData, setTotalFilterData] = useState(0);
    const [searchClicked, setSearchClicked] = useState(false);


    useEffect(() => {
        if (inputText.length > 0) {
            setSearchClicked(true);
        } else {
            setSearchClicked(false)
        }

    }, [inputText])

    useEffect(() => {
        if (dataStartDate && dataEndDate != null) {
            setFilterClicked(true);
        }
    }, [dataStartDate, dataEndDate])


    const handleChangeInput = (e) => {
        ViewUserDataFunc(1); setFilterClicked(false); setDataStartDate(null); setStartDate(moment().format('L'));
        setEndDate(moment().format('L'))
        setInputText(e.target.value);
        // console.log("eeeee", e.target.value)
    };

    const totalDataFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/totalDataforUser`,
        };
        axios(patchItem)
            .then((response) => {
                if (response.data.length > 0) {
                    setTotalData(response.data[0].count)
                    // console.log("item @@@@@@@@@@@", response.data)
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        totalDataFunc()
    }, [])

    const handleChangeForLimit = (e) => {
        setLimit(e.target.value)
        // console.log("evee", e.target.value)
    }


    const handleChangeOffset = (page) => {
        // console.log("@@@@@@@@", page)
        setOffset((page - 1) * limit)
        ViewUserDataFunc((page - 1) * limit)

        if (searchClicked) {
        } else if (dataStartDate != null && dataEndDate != null) {
            ViewUserDataFilterFunc((page - 1) * limit)
        }
    }


    const onClickEventFunc = (index) => {
        setIndexValue(index);
        setCheck(prevCheck => !prevCheck);
    }

    useEffect(() => {
        setRefresh(1)
    }, [])


    useEffect(() => {
        if (indexValue && indexValue != null || indexValue === 0) {
            if (check) {
                if (selectedColIndex != null) {
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                }
                setSelectedColIndex(indexValue)
                document.getElementById(`newClass${indexValue}`).style.display = "flex";
            } else {
                if (selectedColIndex === indexValue) {
                    document.getElementById(`newClass${indexValue}`).style.display = "none";
                } else {
                    setSelectedColIndex(indexValue)
                    document.getElementById(`newClass${selectedColIndex}`).style.display = "none";
                    document.getElementById(`newClass${indexValue}`).style.display = "flex";
                }
            }
        }
    }, [check, indexValue])

    useEffect(() => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
    }, [todayDate])

    const handleEvent1 = (event, picker) => {
        // setStartDate(picker.startDate._d);
        // setEndDate(picker.endDate._d);
        setDataStartDate(moment(picker.startDate._d).format("YYYY-MM-DD"));
        setDataEndDate(moment(picker.endDate._d).format("YYYY-MM-DD"));
    }
    const TodayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(todayDate);
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const YesterdayFunc = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'days'));
        setEndDate(moment(todayDate).subtract(1, 'days'));
        setShowDateCon(false);
        setDataStartDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    }
    const LastWeek = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(6, 'days'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataStartDate(moment().subtract(6, 'days').format('YYYY-MM-DD'));
        setDataEndDate(moment().format('YYYY-MM-DD'))
    }
    const LastDays = () => {
        setTodayDate(moment().format('L'))
        setStartDate(moment(todayDate).subtract(1, 'months'));
        setEndDate(todayDate);
        setShowDateCon(false);
        setDataEndDate(moment().format('YYYY-MM-DD'));
        setDataStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    }
    const ThisMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month'));
        setEndDate(moment().endOf('month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').format('YYYY-MM-DD'));
    }
    const LastMonth = () => {
        setTodayDate(moment().format('L'));
        setStartDate(moment().startOf('month').subtract(1, 'months'));
        setEndDate(moment().endOf('month').subtract(1, 'month'));
        setShowDateCon(false);
        setDataStartDate(moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
        setDataEndDate(moment().endOf('month').subtract(1, 'months').format('YYYY-MM-DD'));
    }

    console.log("inputstate", searchClicked);

    if (searchClicked) {

    } else {

    }

    const ViewUserDataFunc = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewUserDefault?inputText=${inputText}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])

                // setOffset(offset + 500)
                setRowData([])
                setViewUserData(response.data)
                // console.log("for id", response.data)

                if (inputText === "") {
                    setExtraData(true)
                    // console.log("doneeeeeeeeee")
                } else {
                    setExtraData(false)
                    // setExtraTotal(response.data.length)
                }


            })
            .catch((error) => {
                console.log(error);
            })
    }


    const ViewUserDataFuncSearchCount = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewUserSearchCount?inputText=${inputText}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])

                // setOffset(offset + 500)
                setTotalFilterData(response.data[0].count)
                // console.log("for search count", response.data)


            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        ViewUserDataFuncSearchCount()
    }, [inputText])



    const ViewUserDataFilterFunc = (offset) => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getviewUser?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}&limit=${limit}&offset=${offset}`,
        };
        axios(patchItem)
            .then((response) => {
                setRowData([])
                setViewUserData(response.data)

            })
            .catch((error) => {
                console.log(error);
            })
    }

    console.log(filterClicked, "filterclickkkkkkkkkk")
    console.log(searchClicked, "searchClicked")

    const ViewTotalDataFilterFunc = () => {
        const patchItem = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getviewUserTotalFilter?dataStartDate=${dataStartDate}&dataEndDate=${dataEndDate}`,
        };
        axios(patchItem)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                setTotalFilterData(response.data[0].count)
                // console.log("count of data", response.data)

            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        ViewTotalDataFilterFunc()
    }, [dataStartDate, dataEndDate])

    useEffect(() => {

        if (dataStartDate === null) {
            ViewUserDataFunc(0)
        } else if (dataStartDate != null && !searchClicked) {
            ViewUserDataFilterFunc(0)
        }

    }, [dataStartDate, dataEndDate, limit, inputText])

    const deleteUser = (mobile_number) => {
        if (window.confirm("are you sure you want to delete this user")) {
            const dltuser = {
                method: "DELETE",
                // url: Helper.getWsUrl()(cookies.skip) + `/booking/add_booking_chat_new/${ibIdForApi}/?web=1`,
                url: Helper.getWsUrl() + `/api/deleteUser/${mobile_number}`,
                // headers: { "Content-Type": "application/json", 'token': cookies.token },

            }
            axios(dltuser)

                .then((response) => {
                    // console.log("repokpkoj", response)
                    if (response.status === 200) {
                        setCheck(false)
                    }

                })
                .catch((error) => {
                    console.log(error);
                })

            setTimeout(() => {
                ViewUserDataFunc(0)
            }, 500)
        }

    }
    // useEffect(()=>{
    //   if(viewUserData.profile_type_id === 2){

    //   }
    // },[])

    useEffect(() => {
        if (viewUserData && viewUserData.length > 0) {
            viewUserData && viewUserData.map((value, index) => {
                if (!rowData.find((ele) => ele.id === value.id)) {
                    setRowData((prevState) => [
                        ...prevState, {
                            key: index,
                            id: value.id,
                            blank: <th scope="row" style={{ position: "relative" }}>
                                <AddCircleIcon onClick={() => { setIndexValue(index); onClickEventFunc(index) }} style={{ color: "#3d86c6" }} >
                                </AddCircleIcon>
                                <div className='vutableaddIcon' id={`newClass${index}`} >
                                    <div className='contentContainer' onClick={() => { setUpdateUserNumber(value.mobile_number); navigate("/UpdateUser"); setUserId(value.id) }}>
                                        <div>
                                            <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Update</p>
                                        </div>
                                        <div>
                                            <EditIcon fontSize='small' style={{ color: "rgb(61, 134, 198)" }}></EditIcon>
                                        </div>
                                    </div>
                                    <div className='line'>
                                    </div>
                                    <div className='contentContainer'>
                                        <div>
                                            <p style={{ marginBottom: "0px", paddingTop: "2px", marginRight: "10px", cursor: "default" }} >Delete</p>
                                        </div>
                                        <div>
                                            <DeleteIcon onClick={() => { deleteUser(value.id) }} fontSize='small' style={{ color: "rgb(61, 134, 198)" }}> </DeleteIcon>
                                        </div>
                                    </div>

                                </div>
                            </th>,
                            UserName: value.login_id,
                            Name: value.name,
                            Phone: value.mobile_number,
                            Status: value.is_active,
                            Profile: value.profile_type === 2 ? "sales person" : value.profile_type === 8 ? "admin" : value.profile_type === 9 ? "telecaller" : null,
                            // Profile:value.profile_type_id ,
                            Address: value.address,
                            Pincode: value.pincode_text,
                            City: value.city_text,
                            State: value.state_text,
                            Landmark: value.landmark
                        }
                    ])
                }

            })
        }
    }, [viewUserData])

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    const data = {
        columns: [
            {
                label: '',
                field: 'blank',
                sort: 'asc',
                width: 150
            },
            {
                label: 'UserName',
                field: 'UserName',
                sort: 'asc',
                width: 270
            },
            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Phone',
                field: 'Phone',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Status',
                field: 'Status',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Profile Type',
                field: 'Profile',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Address',
                field: 'Address',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Pincode',
                field: 'Pincode',
                sort: 'asc',
                width: 100
            },
            {
                label: 'City',
                field: 'City',
                sort: 'asc',
                width: 100
            },
            {
                label: 'State',
                field: 'State',
                sort: 'asc',
                width: 100
            }
            ,
            {
                label: 'Landmark',
                field: 'Landmark',
                sort: 'asc',
                width: 100
            }

        ],
        rows:
            rowData && rowData
    }

    // console.log("page changed")

    const handlePageChange = (page) => {
        // console.log("@@@@@@@@@@@@", page)
    }

    // console.log("rowwwwwww", rowData)
    useEffect(() => {
        if (!searchClicked) {
            setPageNumber(1)
        }
    }, [searchClicked])

    useEffect(()=>{
        if(dataStartDate != null){
            setPageNumber(1)
        }
    },[dataStartDate])
    return (
        <>

            <div className='row '>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>View User</h4>

                    <div className='vmdate' >
                        <input value={startDate && endDate && `${moment(startDate).format('L')} - ${moment(endDate).format('L')}`} readOnly className='vmdateinput' onClick={() => { setShowDateCon(!showDateCon); setExtraData(false); setInputText(""); }}></input>
                        {showDateCon ?
                            <>
                                <div className='dateOptionContainer'>
                                    <div className='dateOption' onClick={() => { TodayFunc() }} >
                                        Today
                                    </div>
                                    <div className='dateOption' onClick={() => { YesterdayFunc() }}>
                                        Yesterday
                                    </div>
                                    <div className='dateOption' onClick={() => { LastWeek() }}>
                                        Last 7 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { LastDays() }} >
                                        Last 30 Days
                                    </div>
                                    <div className='dateOption' onClick={() => { ThisMonth() }}>
                                        This Month
                                    </div>
                                    <div className='dateOption' onClick={() => { LastMonth() }}>
                                        Last Month
                                    </div>
                                    <DateRangePicker onApply={handleEvent1} >
                                        <button type="button" className="dateOption">
                                            Custom Range
                                        </button>
                                    </DateRangePicker>
                                    <div>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmapply' style={{ marginLeft: "5px", marginRight: "5px", color: "white" }}>Apply</button>
                                        <button onClick={() => { setShowDateCon(false) }} className='dateoptionbtn' id='vmcancel'>Cancel</button>
                                    </div>
                                </div>
                                {showCal ?
                                    <>
                                        <div style={{ position: "absolute", top: "30px", left: "21%" }}>
                                        </div>
                                    </>
                                    : null}
                            </>
                            : null}
                    </div>
                    <div style={{ padding: "2%" }}>
                        {loading ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                <Skeleton count={10} width={900} height={50} />
                            </div> :
                            <>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <div style={{ display: "flex", textAlign: "center", position: "absolute", 
                                        // top: "31%"
                                         }}>
                                            <p>show</p>
                                            <select onChange={(e) => { handleChangeForLimit(e) }} id="dropdown" style={{ height: "20px", marginLeft: "5px", marginRight: "5px" }}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <p>entries</p>
                                        </div>
                                    </div>

                                    {/* {dataStartDate != null && dataEndDate != null ? <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} onClick={() => {
                                            ViewUserDataFunc(1); setFilterClicked(false);; setDataStartDate(null); setStartDate(moment().format('L'));
                                            setEndDate(moment().format('L'));
                                        }} ></input>
                                    </div> : <div>
                                        <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>
                                    </div>} */}

                                    <input placeholder='search' onChange={handleChangeInput} value={inputText} ></input>


                                </div>

                                <MDBDataTable
                                    className='example'
                                    striped
                                    small
                                    searching={false}
                                    data={data}
                                    paging={false}
                                />
                                <>
                                    {filterClicked || searchClicked ?
                                        <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {filterTotalData} entries</p>
                                            <Pagination page={pageNumber} count={Math.ceil(filterTotalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div> :
                                        <div style={{ display: "flex", justifyContent: "start", width: "100%" }}>
                                            <p style={{ width: "30%" }}>Showing {offset + 1} to {limit * pageNumber} of {totalData} entries</p>
                                            <Pagination page={pageNumber} count={Math.ceil(totalData / limit)} onChange={(e, page) => { handleChangeOffset(page); setPageNumber(page) }} variant="outlined" />
                                        </div>
                                    }


                                </>
                            </>}

                    </div>

                </div>
            </div>
        </>
    )
}

export default ViewUser


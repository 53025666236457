import './AddUser.css';
import HomeBack from '../Components/HomeBack';
import SideBar from '../Components/SideBar';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyIcon from '@mui/icons-material/Key';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Helper from '../Util/Helper';
import { Navigate, useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const UpdateUser = ({ refresh, userId, setUserId, updateUserNumber, setUpdateUserNumber }) => {
    const navigate = useNavigate();
    // console.log("id is commiung", userId)
    const [editUser, setEditUser] = useState(null);
    const [userPassword, setUserPassword] = useState(null);

    // const[updatedUserName,setUpdatedUserName]=useState(null);
    // const[updatedNumber,setUpdatedNumber]=useState(null);
    // const[updatedLoginId,setUpdatedLoginId]=useState(null);
    // const[updatedProfileType,setUpdatedProfileType]=useState(null);
    // const[updatedAddress,setUpdatedAddress]=useState(null);
    // const[updatedPincode,setUpdatedPincode]=useState(null);
    // const[updatedCity,setUpdatedCity]=useState(null);
    // const[updatedState,setUpdatedState]=useState(null);
    // const[updatedLandmark,setUpdatedLandmark]=useState(null);

    // console.log("refresh", refresh)

    // useEffect(() => {
    //     if (refresh != null) {

    //     } else {
            
    //     }
    // }, [])

    useEffect(() => {
        const getUser = {
            method: "GET",
            url: Helper.getWsUrl() + `/api/getViewUserEdit?mobile_number=${updateUserNumber}`,
        };
        axios(getUser)
            .then((response) => {
                // setRowData([])
                // setViewUserData(response.data)
                // console.log("mobile number data", response.data[0].name)
                setEditUser(response.data[0])

            })
            .catch((error) => {
                navigate("/ViewUser")
                console.log(error);
            })
    }, [])



    const addUserPost = () => {
        const request = {
            method: "PUT",
            // url: Helper.getWsUrl()(cookies.skip) + `/booking/add_booking_chat_new/${ibIdForApi}/?web=1`,
            url: Helper.getWsUrl() + `/api/putViewUserEdit?id=${userId}`,
            // headers: { "Content-Type": "application/json", 'token': cookies.token },
            data: {
                // temp_id: selectedChatTempId && selectedChatTempId,
                // added_by: 0

                name: editUser && editUser.name,
                mobile_number: editUser && editUser.mobile_number,
                password: editUser && editUser.password,
                profile_type: editUser && editUser.profile_type,
                is_verified: editUser && 1,
                login_id: editUser && editUser.login_id,
                address: editUser && editUser.address,
                pincode_text: editUser && editUser.pincode_text,
                city_text: editUser && editUser.city_text,
                state_text: editUser && editUser.state_text,
                landmark: editUser && editUser.landmark,
                is_active: editUser && 1,
            }
        }
        axios(request)
            .then((response) => {
                alert("edited successfully")
            })
            .catch((error) => {
                console.log(error);
            })
    }






    return (
        <>
            <div className='row Adduser' style={{ margin: "0", padding: "0" }}>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>Update Details</h4>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <PersonIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></PersonIcon>
                                <input className='auinput' placeholder='Enter Name' value={editUser && editUser.name} onChange={(event) => { setEditUser({ ...editUser, name: event.target.value }) }}></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocalPhoneIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocalPhoneIcon>
                                <input value={editUser && editUser.mobile_number} onChange={(event) => { setEditUser({ ...editUser, mobile_number: event.target.value }) }} type="number" className='auinput' placeholder='Enter Phone Number'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <PersonIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></PersonIcon>
                                <input value={editUser && editUser.login_id} onChange={(event) => { setEditUser({ ...editUser, login_id: event.target.value }) }} className='auinput' placeholder='Enter Username'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LockIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LockIcon>
                                <input type="password" value={editUser && editUser.password} onChange={(event) => { setEditUser({ ...editUser, password: event.target.value }) }} className='auinput' placeholder='Enter Password'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <ArrowDropDownIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></ArrowDropDownIcon>
                                <select value={editUser && editUser.profile_type} onChange={(event) => { setEditUser({ ...editUser, profile_type: event.target.value }) }} style={{ fontSize: "13px" }} class="form-control rounded audropdown" id="userprofile" name="userprofile">
                                    <option style={{ display: "hide" }} hidden >Select UserType</option>
                                    <option value={2} >Sales Person</option>
                                    <option value={8} >Admin</option>
                                    <option value={9} >TeleCaller</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <ContactsIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></ContactsIcon>
                                <input value={editUser && editUser.address} onChange={(event) => { setEditUser({ ...editUser, address: event.target.value }) }} className='auinput' placeholder='Enter Address'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <KeyIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></KeyIcon>
                                <input value={editUser && editUser.pincode_text} onChange={(event) => { setEditUser({ ...editUser, pincode_text: event.target.value }) }} className='auinput' placeholder='Enter Pincode'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocationCityIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocationCityIcon>
                                <input value={editUser && editUser.city_text} onChange={(event) => { setEditUser({ ...editUser, city_text: event.target.value }) }} className='auinput' placeholder='Enter City'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocationCityIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocationCityIcon>
                                <input value={editUser && editUser.state_text} onChange={(event) => { setEditUser({ ...editUser, state_text: event.target.value }) }} className='auinput' placeholder='Enter State'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LockIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LockIcon>
                                <input value={editUser && editUser.landmark} onChange={(event) => { setEditUser({ ...editUser, landmark: event.target.value }) }} className='auinput' placeholder='Enter Landmark'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <button onClick={addUserPost} className='addUserbtn' >Update</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default UpdateUser 
import './AddUser.css';
import HomeBack from '../Components/HomeBack';
import SideBar from '../Components/SideBar';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockIcon from '@mui/icons-material/Lock';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ContactsIcon from '@mui/icons-material/Contacts';
import KeyIcon from '@mui/icons-material/Key';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import Helper from '../Util/Helper';

const AddUser = () => {
    const [auName, setAuName] = useState("");
    const [auNumber, setAuNumber] = useState("");
    const [auUserName, setAuUserName] = useState("");
    const [auPassword, setAuPassword] = useState("");
    const [auUserType, setAuUserType] = useState("");
    const [auAdress, setAuAdress] = useState("");
    const [auPincode, setAuPincode] = useState("");
    const [auCity, setAuCity] = useState("");
    const [auState, setAuState] = useState("");
    const [auLandmark, setAuLandmark] = useState("");

    var CurrentDate = moment().format('YYYY-MM-DD HH:mm:ss.SSS');
    const uuid = uuidv4();

    //  useEffect(()=>{
    // console.log("uuuidd", auUserType)
    //  },[])

    const addUserPost = () => {
        if (auUserName.length === 0 || auPassword.length === 0 || auUserType.length === 0) {
            alert("add required field")

        } else {
            const request = {
                method: "POST",
                // url: Helper.getWsUrl()(cookies.skip) + `/booking/add_booking_chat_new/${ibIdForApi}/?web=1`,
                url: Helper.getWsUrl() + `/api/postAddUser`,
                headers: {
                    "Content-Type": "application/json", 
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Headers': 'Authorization, Content-Type',
                    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
                  },
                // headers: { "Content-Type": "application/json", 'token': cookies.token },
                data: {
                    // temp_id: selectedChatTempId && selectedChatTempId,
                    // added_by: 0

                    name: auName,
                    mobile_number: auNumber,
                    password: auPassword,
                    login_id: auUserName,
                    address: auAdress,
                    profile_type: auUserType,
                    pincode_text: auPincode,
                    landmark: auLandmark,
                    city_text: auCity,
                    state_text: auState,
                    is_active: 1,
                    is_verified: 1,
                    add_date_time: CurrentDate


                }
            }
            axios(request)
                .then((response) => {
                    alert("added successfully")
                })
                .catch((error) => {
                    console.log(error);
                })
        }




    }

    const handleChange = (e) => {
        setAuUserType(e.target.value)
    }
    const inputElement = document.getElementById("myInput");
    const maxLength = 13;

    inputElement && inputElement.addEventListener("input", () => {
        if (inputElement.value.length > maxLength) {
            inputElement.value = inputElement.value.slice(0, maxLength);
        }
    });
    return (
        <>
            <div className='row Adduser' style={{ margin: "0", padding: "0" }}>
                <SideBar></SideBar>
                <div className='col-sm-12 col-md-9 col-lg-10 AdduserCol' style={{ margin: "0", padding: "0" }}>
                    <HomeBack></HomeBack>
                    <h4 style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "30px" }}>Add User</h4>

                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <PersonIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></PersonIcon>
                                <input onChange={(event) => {
                                    setAuName(event.target.value);
                                }} className='auinput' placeholder='Enter Name'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocalPhoneIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocalPhoneIcon>
                                <input id="myInput" onChange={(event) => {
                                    setAuNumber(event.target.value);
                                }} type="number" className='auinput' placeholder='Enter Phone Number'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <PersonIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></PersonIcon>
                                <input onChange={(event) => {
                                    setAuUserName(event.target.value);
                                }} className='auinput' placeholder='Enter Username *'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LockIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LockIcon>
                                <input type="password" onChange={(event) => {
                                    setAuPassword(event.target.value);
                                }} className='auinput' placeholder='Enter Password *'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <ArrowDropDownIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></ArrowDropDownIcon>
                                <select onChange={(e) => handleChange(e)} style={{ fontSize: "13px" }} class="form-control rounded audropdown" id="userprofile" name="userprofile">
                                    <option style={{ display: "hide" }} hidden >Select UserType *</option>
                                    <option value={2}>Sales Person</option>
                                    <option value={8}>Admin</option>
                                    <option value={9}>TeleCaller</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <ContactsIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></ContactsIcon>
                                <input onChange={(event) => {
                                    setAuAdress(event.target.value);
                                }} className='auinput' placeholder='Enter Address'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <KeyIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></KeyIcon>
                                <input onChange={(event) => {
                                    setAuPincode(event.target.value);
                                }} className='auinput' placeholder='Enter Pincode'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocationCityIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocationCityIcon>
                                <input onChange={(event) => {
                                    setAuCity(event.target.value);
                                }} className='auinput' placeholder='Enter City'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LocationCityIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LocationCityIcon>
                                <input onChange={(event) => {
                                    setAuState(event.target.value);
                                }} className='auinput' placeholder='Enter State'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <LockIcon fontSize='small' style={{ color: "gray", position: "absolute", left: "8px", top: "8px" }}></LockIcon>
                                <input onChange={(event) => {
                                    setAuLandmark(event.target.value);
                                }} className='auinput' placeholder='Enter Landmark'></input>
                            </div>
                        </div>
                    </div>
                    <div className='inputWrapper'  >
                        <div className='inputsContainer' >
                            <div className='inputWrapper'>
                                <button onClick={() => { addUserPost() }} className='addUserbtn' >Add</button>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </>
    )
}
export default AddUser 